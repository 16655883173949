import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { RetoComponent } from './reto/reto.component';
import { RouterLink } from '@angular/router';
import { Router } from '@angular/router';
import { RetosService } from '@services/retos/retos.service';
import { TranslateService } from '@services/translate/translate.service';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { Subscription, catchError, throwError } from 'rxjs';
import { DataShareService } from '@services/data-share/data-share.service';

@Component({
  selector: 'retos',
  standalone: true,
  imports: [CommonModule, RetoComponent, RouterLink, AngularMaterialModule],
  templateUrl: './retos.component.html',
  styleUrls: ['./retos.component.scss'],
})
export class RetosComponent implements OnInit, OnDestroy {
  items: any[] = [];
  retoActivo: any;
  showItems: any = [];
  showAllNews = true;
  isLoading = true;
  languageSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private retosService: RetosService,
    private dataShareService: DataShareService,
    public translateService: TranslateService
  ) {}

  // Initializes the component and subscribes to language changes to reload the list of challenges
  ngOnInit() {
    try {
      this.languageSubscription = this.translateService.language$
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (language) => {
            this.getActions();
          },
          (err) => console.log('Error:', err),
          () => console.log('Request completed')
        );
    } catch (error) {
      console.error('Error in ngOnInit:', error);
    }
  }

  // Unsubscribes from the language subscription to prevent memory leaks
  ngOnDestroy() {
    try {
      if (this.languageSubscription) {
        this.languageSubscription.unsubscribe();
      }
    } catch (error) {
      console.error('Error in ngOnDestroy:', error);
    }
  }

  // Fetches the list of available challenges from the API
  getActions() {
    try {
      this.retosService
        .getActions()
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (actions: any[]) => {
            console.log('API response:', actions);
            if (actions && actions.length > 0) {
              this.showItems = actions;
            } else {
              this.showItems = [];
              console.warn('No valid challenges available.');
            }
            console.log('Challenges displayed:', this.showItems);
            this.isLoading = false;
          },
          (error) => {
            console.error('Error fetching challenges:', error);
            this.isLoading = false;
          }
        );
    } catch (error) {
      console.error('Error in getActions:', error);
      this.isLoading = false;
    }
  }

  // Handles the selection of a challenge, saves it, and navigates to the active challenge page
  handleReto(reto: any) {
    try {
      console.log('Selected challenge:', reto);
      this.dataShareService.setReto(reto); // Send the challenge to the service
      localStorage.setItem('storeRetoActivo', JSON.stringify(reto));
      this.router.navigate(['/reto-activo', reto.id]); // Navigate to the active challenge page
    } catch (error) {
      console.error('Error in handleReto:', error);
    }
  }

  // Checks if the current page is the main page
  isMainpage(): boolean {
    try {
      return this.router.url !== '/inicio';
    } catch (error) {
      console.error('Error checking the main page:', error);
      return false;
    }
  }

  // Updates the list of items displayed, filtering out the active challenge if applicable
  updateShowItems() {
    try {
      if (this.retoActivo) {
        this.showItems = this.items.filter((item) => item.id !== this.retoActivo);
      } else {
        this.showItems = [...this.items];
      }
    } catch (error) {
      console.error('Error in updateShowItems:', error);
    }
  }

  // Navigates to a specific section of the Wiki
  navigateToWikiSection() {
    try {
      this.dataShareService.setSelectedWikiSectionIndex(6);
      this.router.navigate(['/wiki']); // Navigate to the Wiki page
    } catch (error) {
      console.error('Error in navigateToWikiSection:', error);
    }
  }
}