import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subscription, catchError, throwError } from 'rxjs';
import { TranslateService } from '@services/translate/translate.service';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { ParticipantesService } from '@services/participantes/participantes.service';

@Component({
  selector: 'participantes',
  standalone: true,
  imports: [AngularMaterialModule],
  templateUrl: './participantes.component.html',
  styleUrls: ['./participantes.component.scss'],
})
export class ParticipantesComponent implements OnChanges, OnInit {
  @Input() users: any[] = [];
  isLoading = true;
  paginatorConfig: any = {
    pageIndex: 0,
    length: 0,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 50],
  };

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private rankingListSub!: Subscription;

  constructor(
    private participantesService: ParticipantesService,
    public translateService: TranslateService
  ) {}

  // Called when the component is initialized to load user data
  ngOnInit(): void {
    try {
      this.cargarUsuarios();
    } catch (error) {
      console.error('Error en ngOnInit:', error);
    }
  }

  // Called when input properties (like 'users') change to update the user list
  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes['users'] && changes['users'].currentValue) {
        this.users = changes['users'].currentValue;
      }
    } catch (error) {
      console.error('Error en ngOnChanges:', error);
    }
  }

  // Fetches and processes the list of users from the service
  cargarUsuarios(): void {
    this.participantesService
      .getUsuarios()
      .pipe(
        catchError((err: any) => {
          console.log('Error occurred', err);
          return throwError(err);
        })
      )
      .subscribe(
        (data) => {
          try {
            if (data && data.data && data.data[0] && data.data[0].user) {
              // Limit the number of users to 8
              this.users = [...data.data[0].user]
                .sort((a, b) => a.position - b.position)
                .slice(0, 8);
            } else {
              this.users = [];
            }
            this.isLoading = false;
          } catch (error) {
            console.error('Error al procesar los datos de usuarios:', error);
            this.isLoading = false;
          }
        },
        (error) => {
          console.error('Error al cargar los participantes:', error);
          this.isLoading = false;
        }
      );
  }

  // Returns the URL of the level image for a user
  getLevelImage(user: any): string {
    try {
      if (user.level && user.level.file && user.level.file.image.length > 0) {
        return user.level.file.image[0].url;
      } else {
        // Default star image
        return 'https://t3.ftcdn.net/jpg/01/21/64/88/360_F_121648819_ZQ0tZ6tjLzxim1SG7CQ86raBw4sglCzB.jpg';
      }
    } catch (error) {
      console.error('Error al obtener la imagen del nivel:', error);
      // Default star image in case of error
      return 'https://t3.ftcdn.net/jpg/01/21/64/88/360_F_121648819_ZQ0tZ6tjLzxim1SG7CQ86raBw4sglCzB.jpg';
    }
  }

  // Returns the URL of the profile image for a user
  getProfileImage(user: any): string {
    try {
      // Logic to get the profile image
      return 'https://e7.pngegg.com/pngimages/122/453/png-clipart-computer-icons-user-profile-avatar-female-profile-heroes-head-thumbnail.png';
    } catch (error) {
      console.error('Error al obtener la imagen de perfil:', error);
      // Returns a default image in case of error
      return 'https://e7.pngegg.com/pngimages/122/453/png-clipart-computer-icons-user-profile-avatar-female-profile-heroes-head-thumbnail.png';
    }
  }

  // Updates the pagination settings when the user changes the page
  onPageChange(event: PageEvent): void {
    try {
      this.paginatorConfig.pageIndex = event.pageIndex;
      this.paginatorConfig.pageSize = event.pageSize;
    } catch (error) {
      console.error('Error en onPageChange:', error);
    }
  }

  // Cleans up the subscription to prevent memory leaks when the component is destroyed
  ngOnDestroy() {
    try {
      if (this.rankingListSub) {
        this.rankingListSub.unsubscribe();
      }
    } catch (error) {
      console.error('Error en ngOnDestroy:', error);
    }
  }
}