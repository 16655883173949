<div class="container-retos" [ngClass]="{ 'retos-details': isMainpage() }">
  <mat-spinner *ngIf="isLoading"></mat-spinner>

  <div class="no-results" *ngIf="showItems.length === 0 && !isLoading">
    <h2>
      {{ translateService.translate("MAIN.ERRORS.CHALLENGES_NOT_FOUND") }}
    </h2>
  </div>

  <div *ngIf="showItems.length > 0 && !isLoading" class="retos">
    <div class="title-underlined">
      <h2 *ngIf="!retoActivo">
        {{ translateService.translate("MAIN.COMPONENTS.ACTIVE_CHALLENGES") }}
      </h2>
    </div>

    <h2 *ngIf="retoActivo">
      {{ translateService.translate("ACTIVE_CHALLENGE.OTHERS") }}
    </h2>

    <div *ngIf="!isMainpage()" class="title-bg-image">
      <img src="../../../../assets/circles-bg-1.png" />
    </div>

    <div *ngFor="let i of showItems; let idx = index">
      <div
        *ngIf="(retoActivo && retoActivo != i.id) || !retoActivo"
        class="reto-container"
        (click)="handleReto(i)"
        [routerLink]="['/reto-activo', i.id]"
      >
        <reto [reto]="i" [index]="idx"></reto>
      </div>
    </div>
  </div>

  <br />
  <button
    mat-flat-button
    class="custom-button text-white-color"
    color="warn"
    (click)="navigateToWikiSection()"
  >
    <p>
      {{ translateService.translate("MAIN.BUTTONS.HOW_TO_GET_POINTS") }}
    </p>
  </button>
</div>
