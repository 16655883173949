import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { TranslateService } from '@services/translate/translate.service';
@Component({
  selector: 'reto',
  standalone: true,
  imports: [AngularMaterialModule, RouterLink],
  templateUrl: './reto.component.html',
  styleUrls: ['./reto.component.scss']
})
export class RetoComponent {
  @Input() reto: any;
  @Input() index: any;
  constructor(public translateService: TranslateService) {}
}
