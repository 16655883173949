import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ParticipantesService {
  baseURL: string;

  // Constructor to initialize the service with HttpClient and AuthService injections
  constructor(
    private http: HttpClient, // Inject HttpClient for making HTTP requests
    private authService: AuthService // Inject AuthService for handling authorization headers
  ) {
    // Determine the base URL depending on whether the environment is local or deployed
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      // Use the deployed environment's URL when not local
      this.baseURL = `${environment.url}`;
    }
  }

  // Method to fetch users from the specified ranking API endpoint
  getUsuarios(): Observable<any> {
    const url = `${this.baseURL}${environment.api_ranking}?channel_id=2`; // Construct the full API URL
    console.log('Request URL:', url); // Log the request URL for debugging purposes

    // Make an HTTP GET request to retrieve the list of users and pass authentication headers
    return this.http.get<any>(url, {
      headers: this.authService.getHeadersWithToken(), // Attach token in request headers for authorization
    });
  }
}
