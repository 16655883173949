<mat-card
  [ngClass]="{ 'noticia-card': true, 'noticia-card-allnews': showAllNews }"
>
  <div class="noticia animation-hover">
    <!-- Image at the top -->
    <div class="image-container">
      <div class="image">
        <!-- <img
          *ngIf="noticia?.foto"
          mat-card-image
          [src]="noticia?.foto"
          alt="Imagen de la noticia"
        /> -->
        <img src="https://github.com/sYlskY7.png" alt="" />
      </div>
    </div>
    <!-- Content (text) below the image -->
    <mat-card-content *ngIf="noticia?.lang">
      <div class="container-right">
        <div class="first">
          <h3 mat-card-title matTooltip="{{ noticia.lang.name }}" class="noticeTitle">{{ noticia.lang.name }} </h3>
          <div class="date-container">
            <mat-icon>calendar_month</mat-icon>
            <p class="news-date">
              {{ noticia.date_start | date : "yyyy/MM/dd" }}
            </p>
          </div>
          <p class="description">
            {{ noticia.lang.short_description }}
          </p>

          <a class="action-link">
            {{ translateService.translate("MAIN.LINKS.READ_MORE") }}
          </a>
        </div>
      </div>
    </mat-card-content>
  </div>
</mat-card>
