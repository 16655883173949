import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DataShareService {
  // Declaration of BehaviorSubjects to manage various data streams.
  private rankingListSubject = new BehaviorSubject<any>([]); // Holds the ranking list data
  private userProfileSubject = new BehaviorSubject<any>([]); // Holds the user profile data
  public updateAuthToken = new BehaviorSubject<any>([]); // Tracks authentication token updates
  private noticiaSubject = new BehaviorSubject<any>(null); // Stores the selected news item
  private retoSubject = new BehaviorSubject<any>(null); // Stores the selected challenge
  private activeChallengeNameSubject = new BehaviorSubject<string | null>(null); // Holds the active challenge's name
  private selectedWikiSectionIndexSubject = new BehaviorSubject<number | null>(
    null
  ); // Tracks the selected section index in the Wiki
  private showMainSubject = new BehaviorSubject<boolean | null>(null); // Controls whether to show the main content or not

  // Exposing BehaviorSubjects as Observables for component subscriptions.
  rankingList$ = this.rankingListSubject.asObservable(); // Observable for ranking list data
  userProfile$ = this.userProfileSubject.asObservable(); // Observable for user profile data
  updateAuthToken$ = this.updateAuthToken.asObservable(); // Observable for auth token updates
  noticia$ = this.noticiaSubject.asObservable(); // Observable for selected news item
  reto$ = this.retoSubject.asObservable(); // Observable for selected challenge
  activeChallengeName$ = this.activeChallengeNameSubject.asObservable(); // Observable for active challenge name
  selectedWikiSectionIndex$ =
    this.selectedWikiSectionIndexSubject.asObservable(); // Observable for selected Wiki section index

  // Methods to update the values in the BehaviorSubjects.

  // Updates the user profile information
  setUserProfile(data: any) {
    this.userProfileSubject.next(data);
  }

  // Updates the authentication token information
  setUpdateAuthToken(data: any) {
    this.updateAuthToken.next(data);
  }

  // Controls the visibility of the main content area
  updateShowMain(showMain: any) {
    this.showMainSubject.next(showMain);
  }

  // Updates the ranking list
  updateRankingList(newList: any) {
    this.rankingListSubject.next(newList);
  }

  // Sets the currently selected news item
  setNoticia(noticia: any) {
    this.noticiaSubject.next(noticia);
  }

  // Sets the currently selected challenge (reto)
  setReto(reto: any) {
    this.retoSubject.next(reto);
  }

  // Updates the state for showing or hiding the main content
  setUpdateShowMain(showMain: boolean | null) {
    this.showMainSubject.next(showMain);
  }

  // Retrieves the current value of showMain as an Observable
  getUpdateShowMain(): Observable<any> {
    return this.showMainSubject;
  }

  // Updates the name of the active challenge
  setActiveChallengeName(name: string | null) {
    this.activeChallengeNameSubject.next(name);
  }

  // Sets the selected section index in the Wiki
  setSelectedWikiSectionIndex(index: number | null) {
    const currentIndex = this.selectedWikiSectionIndexSubject.getValue();
    if (currentIndex !== index) {
      this.selectedWikiSectionIndexSubject.next(index);
    }
  }

  // Retrieves the current Wiki section index and clears it afterward
  getSelectedWikiSectionIndex(): number | null {
    const index = this.selectedWikiSectionIndexSubject.getValue();
    this.clearSelectedWikiSectionIndex(); // Clears the index after fetching
    return index;
  }

  // Clears the selected Wiki section index
  clearSelectedWikiSectionIndex() {
    this.selectedWikiSectionIndexSubject.next(null);
  }
}
