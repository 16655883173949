<div class="participantes">
  <mat-spinner *ngIf="isLoading"></mat-spinner>

  <div *ngIf="!isLoading && (!users || users.length === 0)" class="no-results">
    <h2>
      {{ translateService.translate("MAIN.ERRORS.PARTICIPANTS_NOT_FOUND") }}
    </h2>
  </div>

  <div
    *ngIf="!isLoading && users && users.length > 0"
    class="ranking-container"
  >
    <h2 class="rank-title">Ranking</h2>

    <div class="podium">
      <div class="podium-item second" *ngIf="users[1]">
        <img
          [src]="getProfileImage(users[1])"
          alt="{{ users[1].name }} {{ users[1].surname }}"
        />
        <img
          class="small-image"
          [src]="getLevelImage(users[1])"
          alt="small image"
        />
        <br />
        <span
          >{{ users[1].name }} {{ users[1].surname }}
        </span>
        <div class="podium-rank">2</div>
      </div>

      <div class="podium-item first" *ngIf="users[0]">
        <img [src]="getProfileImage(users[0])" alt="{{ users[0].name }}" />
        <img
          class="small-image"
          [src]="getLevelImage(users[0])"
          alt="small image"
        />
        <br />
        <span
          >{{ users[0].name }} {{ users[0].surname }}</span
        >
        <div class="podium-rank">1</div>
      </div>

      <div class="podium-item third" *ngIf="users[2]">
        <img [src]="getProfileImage(users[2])" alt="{{ users[2].name }}" />
        <img
          class="small-image"
          [src]="getLevelImage(users[2])"
          alt="small image"
        />
        <br />
        <span
          >{{ users[2].name }} {{ users[2].surname }}</span
        >
        <div class="podium-rank">3</div>
      </div>
    </div>

    <ul class="ranking-list">
      <li *ngFor="let user of users.slice(3)">
        <div class="ranking-info">
          <div class="ranking-position">{{ users.indexOf(user) + 1 }}</div>
          <img [src]="getProfileImage(user)" alt="{{ user.name }}" />
          <div>
            <p>{{ user.name }} {{ user.surname }}</p>
            <span>{{ user.points }} puntos</span>
          </div>
        </div>
        <div>
          <img [src]="getLevelImage(user)" alt="User Level" />
        </div>
      </li>
    </ul>
    <button mat-flat-button class="custom-button text-white-color">
      <p>
        {{ translateService.translate("MAIN.LINKS.VIEW_RANKING") }}
      </p>
    </button>
  </div>
</div>
