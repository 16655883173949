import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ActionsService {
  baseURL: string;

  constructor(private authService: AuthService, private http: HttpClient) {
    // Constructor: Initializes the service by setting up the base URL.
    // It forces HTTPS for production environments and uses the local URL for development.
    if (environment.local === true) {
      // Use local development URL and port if running locally
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      // Always enforce HTTPS for non-local environments
      this.baseURL = environment.url.replace('http://', 'https://');
    }
  }

  // Method to add a favorite action
  addFavorite(data: any) {
    // Add the action to the user's favorites with proper headers.
    const headers = this.authService.getHeadersWithToken();
    const body = {
      channel_id: 2, // Specifies the channel ID
      action_id: data.action_id, // Includes the action ID
    };
    const endpoint = `${environment.api_actions_addfavourite}`; // Endpoint for adding favorites
    const httpsUrl = this.baseURL + endpoint; // Ensures the final URL is HTTPS

    // Make the POST request to the add favorite API
    return this.http.post(httpsUrl, body, {
      headers: headers,
    });
  }

  // Method to subscribe to an event
  subscribeToEvent(data: any) {
    // Subscribes the user to an event by sending necessary data and headers.
    const headers = this.authService.getHeadersWithToken();
    const body = {
      channel_id: 2, // Specifies the channel ID
      socialToken: data.socialToken, // Includes the social authentication token
    };
    const endpoint = `${environment.api_actions}/subscribe`; // API endpoint for subscribing to events

    // Make the POST request to subscribe to the event
    return this.http.post(this.baseURL + endpoint, body, {
      headers: headers,
    });
  }
}
