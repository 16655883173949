<div class="user-details-container">
  <header-menu></header-menu>
  <div class="content-container">
    <div class="user-container" [ngClass]="{ 'retos-details': isMainpage() }">
      <div class="user-content">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <div *ngIf="!isLoading">
          <!-- Static title section -->
          <div class="title-underlined">
            <h2>
              {{ translateService.translate("USER_PROFILE.MY_PROFILE") }}
            </h2>
          </div>

          <!-- User profile content section -->
          <div class="descripcion-user">
            <!-- Tabs for different user sections -->
            <mat-tab-group animationDuration="0ms">
              <!-- Info Tab -->
              <mat-tab
                label="{{ translateService.translate('USER_PROFILE.INFO') }}"
              >
                <div class="info-container">
                  <div class="user-photo" (click)="triggerFileUpload()">
                    <img
                      *ngIf="userProfileImage"
                      class="avatar-image"
                      [src]="userProfileImage"
                      alt="Profile Image"
                    />
                    <img
                      class="small-image"
                      [src]="userLevelImage"
                      alt="User Level"
                    />
                    <mat-icon class="edit-icon">add_photo_alternate</mat-icon>
                    <input
                      type="file"
                      id="fileInput"
                      style="display: none"
                      (change)="onFileSelected($event)"
                      accept="image/*"
                    />
                    <p class="text-bold">{{ userName }}</p>
                  </div>

                  <div class="medals-info">
                    <mat-icon>workspace_premium</mat-icon>
                    <div class="medal-count">{{ userVirtualAmount }}</div>
                  </div>

                  <div class="coins-info">
                    <mat-icon>paid</mat-icon>
                    <div class="coin-count">{{ userPoints }}</div>
                  </div>
                  <div class="level-info text-bold">
                    <p>Te faltan 20 puntos para alcanzar el siguiente nivel</p>
                  </div>
                </div>

                <div class="table-container">
                  <div class="title-underlined">
                    <h2 class="growing-title">
                      {{ translateService.translate("USER_PROFILE.GROWING") }}
                    </h2>
                  </div>

                  <!-- Si dataSource tiene datos, muestra la tabla -->
                  <table
                    mat-table
                    [dataSource]="dataSource"
                    class="tabla-puntos"
                    *ngIf="dataSource.length > 0; else noData"
                  >
                    <ng-container matColumnDef="accion">
                      <th mat-header-cell *matHeaderCellDef>
                        {{
                          translateService.translate(
                            "USER_PROFILE.TABLE_ACTION"
                          )
                        }}
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.accion }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="fecha">
                      <th mat-header-cell *matHeaderCellDef>
                        {{
                          translateService.translate("USER_PROFILE.TABLE_DATE")
                        }}
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.fecha | date : "dd/MM/yyyy" }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="puntos">
                      <th mat-header-cell *matHeaderCellDef>
                        {{
                          translateService.translate(
                            "USER_PROFILE.TABLE_OBTAINED_POINTS"
                          )
                        }}
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="puntos-cell"
                      >
                        {{ element.puntos }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="gambacoins">
                      <th mat-header-cell *matHeaderCellDef class="lastTH">
                        {{
                          translateService.translate(
                            "USER_PROFILE.TABLE_GAMBACOINS"
                          )
                        }}
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="gambacoins-cell"
                      >
                        {{ element.gambacoins }}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                  </table>

                  <ng-template #noData>
                    <br /><br />
                    <div class="no-data-message">
                      {{
                        translateService.translate(
                          "USER_PROFILE.TRANSACTIONS_NOT_FOUND"
                        )
                      }}
                    </div>
                  </ng-template>
                </div>
              </mat-tab>

              <!-- Retos Tab -->
              <mat-tab
                label="{{
                  translateService.translate('USER_PROFILE.ACTIVE_CHALLENGES')
                }}"
              >
                <div *ngIf="favouriteActions.length > 0; else noFavourites">
                  <div class="retos-container">
                    <div
                      class="reto-card"
                      *ngFor="let item of favouriteActions"
                    >
                      <img
                        [src]="item.imageUrl"
                        alt="Imagen del reto"
                        class="reto-image"
                      />
                      <div class="reto-content">
                        <h3 class="reto-title" matTooltip="{{ item.title }}">
                          {{ item.title }}
                        </h3>
                        <p class="reto-description">{{ item.description }}</p>
                        <div class="reto-details">
                          <div class="reto-points">
                            <mat-icon>workspace_premium</mat-icon>
                            <span>{{ item.points }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-template #noFavourites>
                  <br /><br />
                  {{
                    translateService.translate(
                      "USER_PROFILE.FAVOURITE_ACTIONS_NOT_FOUND"
                    )
                  }}
                </ng-template>
              </mat-tab>

              <!-- Logros Tab -->
              <mat-tab
                label="{{
                  translateService.translate(
                    'USER_PROFILE.COMPLETED_CHALLENGES'
                  )
                }}"
              >
                <div *ngIf="completedActions.length > 0; else noCompleted">
                  <div class="retos-container">
                    <div
                      class="reto-card"
                      *ngFor="let item of completedActions"
                    >
                      <img
                        [src]="item.imageUrl"
                        alt="Imagen del reto"
                        class="reto-image"
                      />
                      <div class="reto-content">
                        <h3 class="reto-title" matTooltip="{{ item.title }}">
                          {{ item.title }}
                        </h3>
                        <p class="reto-description">{{ item.description }}</p>
                        <div class="reto-details">
                          <div class="reto-points">
                            <mat-icon>workspace_premium</mat-icon>
                            <span>{{ item.points }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-template #noCompleted>
                  <br /><br />
                  {{
                    translateService.translate(
                      "USER_PROFILE.COMPLETED_ACTIONS_NOT_FOUND"
                    )
                  }}
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>

      <!-- Challenges section and points request button -->
      <div
        class="retos-container generic-container"
        [ngClass]="{ 'retos-details': isMainpage() }"
      >
        <retos></retos>
        <br />
         <div class="image-button-puntos" routerLink="/contacto/puntos">
          <div class="container-background"></div>
          <div class="container-text">
            <img src="../../../../assets/boton-solicitar-puntos.png" />
            <div class="h2-button-text">
              {{
                translateService.translate(
                  "CONTACT_FORM.WISH_TO_REQUEST_POINTS"
                )
              }}
            </div>
            <div class="h2-button-text-link">
              {{ translateService.translate("CONTACT_FORM.REQUEST") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <br /><br /><br /><br />
    <div class="image-button-gamba" [routerLink]="['/contacto/soporte']">
      <div class="container-background"></div>
      <div class="container-text">
        <img src="../../../../assets/boton-hablar-gamba.png" />
        <div class="h2-button-text">
          {{ translateService.translate("MAIN.BUTTONS.TALK_TO_THE_GAMBA") }}
        </div>
        <div class="h2-button-text-link">
          {{ translateService.translate("CONTACT_FORM.TALK_NOW") }}
        </div>
      </div>
    </div>

    <br /><br />
    <div class="content-powered-by">
      <img src="../../../../assets/powered-by-logo.png" />
    </div>
  </div>
</div>
