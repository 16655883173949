<div class="header-container">
  <a [routerLink]="['/inicio']" (click)="handleHome()">
    <h2 class="title">
      <img
        src="../../../assets/pecera_logo.png"
        class="header-logo"
        alt="logo"
      />{{appName}}
    </h2>
  </a>

  <div class="menu-container">
    <header class="navbar">
      <div
        class="navbar-left"
        [ngClass]="{ 'align-right': !isNotMainPage() }"
      ></div>
      <div class="navbar-right">
        <a
          matTooltip="Proximamente..."
          routerLink="/tienda"
          class="disabled-button"
        >
          <mat-icon>shopping_cart</mat-icon>
          <span> {{ translateService.translate("MAIN.MENU.STORE") }} </span>
        </a>
        <a routerLink="/wiki">
          <mat-icon>help_outline</mat-icon>
          <span>{{ translateService.translate("MAIN.MENU.WIKI") }}</span>
        </a>
      </div>

      <button
        *ngIf="loggedUserName"
        class="user_menu header-text"
        mat-button
        [mat-menu-trigger-for]="menu"
        fxHide="false"
        fxHide.gt-sm
      >
        <mat-icon *ngIf="!socialUser">manage_accounts</mat-icon>
        <img
          *ngIf="socialUser?.photoUrl"
          class="avatar-image"
          referrerpolicy="no-referrer"
          [src]="socialUser?.photoUrl"
        />
        <span> {{ socialUser.firstName }} </span>
        <mat-icon class="right custom">expand_more</mat-icon>
      </button>

      <mat-menu class="menu main-menu"  #menu="matMenu">
        <button mat-menu-item class="coins">
          <div class="usercoins">
            <mat-icon>toll</mat-icon>
            {{ translateService.translate("MAIN.MENU.YOU_HAVE") }}
            {{userVirtualAmount}} {{ translateService.translate("MAIN.MENU.COINS") }}
          </div>
        </button>
        <button mat-menu-item routerLink="/user-profile">
          {{ translateService.translate("MAIN.MENU.MY_PROFILE") }}
        </button>
        <button mat-menu-item (click)="openConfig()">
          {{ translateService.translate("MAIN.MENU.SETTINGS") }}
        </button>
        <button mat-menu-item routerLink="/contacto/soporte">
          {{ translateService.translate("MAIN.BUTTONS.TALK_TO_THE_GAMBA") }}
        </button>
        <button mat-menu-item (click)="logout()">
          {{ translateService.translate("MAIN.MENU.LOGOUT") }}
        </button>
      </mat-menu>
    </header>
  </div>
</div>
