import { Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { WikiComponent } from '@components/main/wiki/wiki.component';
import { TiendaComponent } from '@components/main/tienda/tienda.component';
import { RetoActivoComponent } from '@components/main/reto-activo/reto-activo.component';
import { NoticiaDetailsComponent } from '@components/main/noticia-details/noticia-details.component';
import { ContactFormComponent } from '@components/contact-form/contact-form.component';
import { UserProfileComponent } from '@components/main/user-profile/user-profile.component';
import { authGuard } from './module/auth-guard/auth.guard';
import { NotFoundComponent } from '@components/not-found/not-found.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'inicio',
    component: MainComponent,
    canActivate: [authGuard]
  },
  {
    path: 'wiki',
    component: WikiComponent,
    canActivate: [authGuard]
  },
  {
    path: 'tienda',
    component: TiendaComponent,
    canActivate: [authGuard]
  },
  {
    path: 'reto-activo/:id',
    component: RetoActivoComponent,
    canActivate: [authGuard]
  },
  {
    path: 'noticia-details/:id',
    component: NoticiaDetailsComponent,
    canActivate: [authGuard]
  },
  {
    path: 'contacto/:formType',
    component: ContactFormComponent,
    canActivate: [authGuard]
  },
  {
    path: 'user-profile',
    component: UserProfileComponent,
    canActivate: [authGuard]
  },
  {
    path: '**',
    component:  NotFoundComponent
  }
];
