import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private baseURL: string;
  private ENDPOINT_LANGUAGE = '/api/v1/language'; 
  private translations: any; 
  private readonly DEFAULT_LANGUAGE = 'en'; 
  private languageSubject = new BehaviorSubject<string>(
    localStorage.getItem('userLanguage') || this.getBrowserLanguage()
  );
  language$ = this.languageSubject.asObservable(); // Observable to track the current language

  constructor(private http: HttpClient, private authService: AuthService) {
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      this.baseURL = `${environment.url}`;
    }
    this.loadTranslations(); // Load translations when the service is initialized
  }

  // Load the translations based on the user's language or fallback to browser language
  loadTranslations() {
    try {
      let userLanguage = localStorage.getItem('userLanguage');
      if (!userLanguage) {
        userLanguage = this.getBrowserLanguage(); // Default to browser language if no stored language
      }
      this.loadTranslationFile(userLanguage); // Load the translation file for the detected language
    } catch (error) {
      console.error('Error loading translations:', error);
      this.loadDefaultTranslations(); // Fallback to default translations on error
    }
  }

  // Load a translation file from the server and set it as the active translation
  use(language: string): Observable<any> {
    const translationUrl = `/assets/i18n/${language}.json`; // URL of the translation file
    return this.http.get<any>(translationUrl).pipe(
      tap((translations) => {
        this.translations = translations; // Save translations
        localStorage.setItem('userLanguage', language); // Store the language setting in localStorage
        this.languageSubject.next(language); // Update the current language
      }),
      catchError((error) => {
        console.error(
          `Error loading translation file for language ${language}:`,
          error
        );
        return of(null); // Return an empty observable in case of error
      })
    );
  }

  // Helper method to load a specific translation file
  private loadTranslationFile(language: string) {
    try {
      const translationUrl = `/assets/i18n/${language}.json`;
      this.http.get<any>(translationUrl).subscribe(
        (translations) => {
          this.translations = translations; // Set the loaded translations
        },
        (error) => {
          console.error(
            `Error loading translation file for language ${language}:`,
            error
          );
          this.loadBrowserLanguage(); // Fallback to the browser language if there's an error
        }
      );
    } catch (error) {
      console.error('Error loading translation file:', error);
      this.loadBrowserLanguage(); // Fallback to the browser language on error
    }
  }

  // Load the language detected from the user's browser
  loadBrowserLanguage() {
    try {
      const browserLanguage = this.getBrowserLanguage();
      const translationUrl = `/assets/i18n/${browserLanguage}.json`;
      this.http.get<any>(translationUrl).subscribe(
        (translations) => {
          this.translations = translations; // Load translations from the browser language
        },
        (error) => {
          console.error('Error loading browser language file:', error);
          this.loadDefaultTranslations(); // Fallback to default translations on error
        }
      );
    } catch (error) {
      console.error('Error loading browser language:', error);
      this.loadDefaultTranslations(); // Fallback on error
    }
  }

  // Load the default translations for the company or fallback to the default language
  private loadDefaultTranslations() {
    try {
      this.getCompanyLanguages().subscribe(
        (response: any) => {
          const defaultLanguage = response.data[0]?.language.find(
            (lang: any) => lang.default === 1 // Find the company's default language
          );
          if (defaultLanguage) {
            this.loadTranslationFile(defaultLanguage.abbreviation); // Load default language file
          } else {
            this.loadTranslationFile(this.DEFAULT_LANGUAGE); // Fallback to 'en'
          }
        },
        (error) => {
          console.error('Error fetching company languages:', error);
          this.loadTranslationFile(this.DEFAULT_LANGUAGE); // Fallback to 'en' on error
        }
      );
    } catch (error) {
      console.error('Error loading default translations:', error);
      this.loadTranslationFile(this.DEFAULT_LANGUAGE); // Final fallback to 'en'
    }
  }

  // Fetch the supported languages of the company
  getCompanyLanguages() {
    try {
      const headers = this.authService.getHeadersWithToken();
      const endpoint = `${this.baseURL}${this.ENDPOINT_LANGUAGE}?channel_id=2`;
      return this.http.get(endpoint, { headers }).pipe(
        catchError((error) => {
          console.error('Error fetching company languages:', error);
          return of([]); // Return an empty observable on error
        })
      );
    } catch (error) {
      console.error('Error fetching company languages:', error);
      return of([]); // Fallback on error
    }
  }

  // Detect the user's browser language
  public getBrowserLanguage(): string {
    return navigator.language.split('-')[0]; // Extract the primary language ('en-US' -> 'en')
  }

  // Translate a specific key into the current language
  translate(key: string): string {
    try {
      if (!this.translations) {
        return key; // If no translations are loaded, return the key itself
      }

      const parts = key.split('.'); // Split the key by periods (for nested translations)
      let translation = this.translations;
      for (const part of parts) {
        translation = translation[part]; // Traverse the translation object
        if (!translation) {
          return key; // Return the key if the translation is not found
        }
      }

      return translation; // Return the final translation
    } catch (error) {
      console.error('Error during translation:', error);
      return key; // Fallback to the key in case of error
    }
  }

  // Get the current language being used
  getCurrentLanguage(): string {
    return this.languageSubject.value;
  }
}
