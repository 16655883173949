import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { NoticiaComponent } from './noticia/noticia.component';
import { AngularMaterialModule } from '../../../module/material/material-module';
import { RouterLink } from '@angular/router';
import { TranslateService } from '@services/translate/translate.service';
import { Subscription, catchError, throwError } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { DataShareService } from '@services/data-share/data-share.service';
import { NoticiasService } from '@services/noticias/noticias.service';

@Component({
  selector: 'noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss'],
  standalone: true,
  imports: [AngularMaterialModule, NoticiaComponent, RouterLink],
})
export class NoticiasComponent implements OnInit, OnDestroy {
  showMainSubscription!: Subscription;
  refreshTokenSubscription!: Subscription;
  languageSubscription!: Subscription;
  @Output('handleNews') showAll: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() parentShowAll: boolean = false;
  noticias: any[] = [];
  showAllNews = true;
  isLoading = true;
  visibleNewsCount = 6; 

  constructor(
    private noticiasService: NoticiasService,
    public translateService: TranslateService,
    private dataShareService: DataShareService,
    private authService: AuthService
  ) {}

  // Initializes the component by fetching news and setting up subscriptions
  ngOnInit(): void {
    try {
      this.showAllNews = false;
      this.showMainSubscription = this.dataShareService
        .getUpdateShowMain()
        .subscribe((update: any) => {
          if (update != undefined) {
            this.resetNewsState();
          }
        });

      this.refreshTokenSubscription = this.dataShareService.updateAuthToken
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (response) => {
            try {
              if (response !== undefined && response === false) {
                this.showAllNews = false;
                this.getData();
              }
            } catch (error) {
              console.error('Error procesando el token:', error);
            }
          },
          (err) => console.log(' Error ', err),
          () => console.log('Request completed')
        );

      this.languageSubscription = this.translateService.language$
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          () => {
            try {
              this.getData();
            } catch (error) {
              console.error('Error en el cambio de idioma:', error);
            }
          },
          (err) => console.log(' Error ', err),
          () => console.log('Request completed')
        );
    } catch (error) {
      console.error('Error en ngOnInit:', error);
    }
  }

  // Cleans up subscriptions to avoid memory leaks
  ngOnDestroy() {
    try {
      if (this.refreshTokenSubscription) {
        this.refreshTokenSubscription.unsubscribe();
      }
      if (this.languageSubscription) {
        this.languageSubscription.unsubscribe();
      }
      if (this.showMainSubscription) {
        this.showMainSubscription.unsubscribe();
      }
    } catch (error) {
      console.error('Error en ngOnDestroy:', error);
    }
  }

  // Handles the click event for a news item
  newsDetails(noticia: any) {
    try {
      console.log('Noticia seleccionada:', noticia);
      this.dataShareService.setNoticia(noticia);
      localStorage.setItem('storeNoticiaActiva', JSON.stringify(noticia));
    } catch (error) {
      console.error('Error al procesar los detalles de la noticia:', error);
    }
  }

  // Fetches news data from the service
  getData() {
    try {
      const expired = this.authService.checkAuthTokenExpiration();
      if (!expired) {
        this.noticiasService
          .getNews()
          .pipe(
            catchError((err: any) => {
              console.log('Error occurred', err);
              return throwError(err);
            })
          )
          .subscribe(
            (data: any) => {
              try {
                if (data) {
                  this.noticias = data;
                }
                this.isLoading = false;
              } catch (error) {
                console.error('Error al procesar los datos de noticias:', error);
                this.isLoading = false;
              }
            },
            (error) => {
              console.error('Error al cargar las noticias:', error);
              this.isLoading = false;
            }
          );
      } else {
        this.authService.refreshToken();
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      this.isLoading = false;
    }
  }

  // Loads more news by increasing the number of visible items
  loadMoreNews() {
    try {
      this.visibleNewsCount += 6; 
    } catch (error) {
      console.error('Error al cargar más noticias:', error);
    }
  }

  // Handles the "show all news" event and emits it to the parent component
  handleNews() {
    try {
      this.showAllNews = true;
      this.showAll.emit(this.showAllNews);  
    } catch (error) {
      console.error('Error al manejar las noticias:', error);
    }
  }

  // Resets the state of the news list to show fewer items
  resetNewsState() {
    try {
      this.showAllNews = false;
      this.visibleNewsCount = 6; 
      this.showAll.emit(false);
    } catch (error) {
      console.error('Error al restablecer el estado de las noticias:', error);
    }
  }
}