import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HeaderComponent } from '@components/header/header.component';
import { RetosComponent } from '../retos/retos.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { TranslateService } from '@services/translate/translate.service';
import { Subscription, catchError, interval, throwError } from 'rxjs';
import { ActionsService } from '@services/actions/actions.service';
import { RetosService } from '@services/retos/retos.service';

@Component({
  selector: 'app-reto-activo',
  standalone: true,
  imports: [HeaderComponent, RetosComponent, AngularMaterialModule, RouterLink],
  templateUrl: './reto-activo.component.html',
  styleUrls: ['./reto-activo.component.scss'],
})
export class RetoActivoComponent implements OnInit, OnDestroy {
  private retoSubscription!: Subscription;
  private countdownSubscription!: Subscription;
  favIcon = 'favorite_border';
  favorited = 'favorite';
  displayIcon: string = '';
  retoId: string = '';
  dateEnd: Date | null = null;
  timeRemaining: string = '';
  participantes = '0';
  puntosRecompensa = 0;
  monedasVirtuales = 0;
  langDescription = '';
  title = '';
  foto = '';
  description = '';
  isLoading = true;
  formType = '';
  joined = 0; // 0 = No unido, 1 = Unido
  retoActivo: any;
  currentLanguage = '';
  userFavorites: any[] = [];
  countUserFavorites = 0;
  maxDisplayUsers = 5;
  isSubscribed = false;

  constructor(
    public translateService: TranslateService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private actionsService: ActionsService,
    private retosService: RetosService
  ) {}
  // Called when the component is initialized. Retrieves challenge details from the URL and loads data.
  ngOnInit() {
    try {
      this.displayIcon = this.favIcon;

      this.activatedRoute.params
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (params) => {
            this.retoId = params['id'];
            console.log('Challenge ID:', this.retoId);

            // Validates if the challenge ID is valid, then checks for stored challenge data
            const storedReto = localStorage.getItem('storeRetoActivo');
            if (storedReto) {
              const parsedReto = JSON.parse(storedReto);
              if (parsedReto && parsedReto.id === this.retoId) {
                this.retoActivo = parsedReto;
                this.setRetoDetails(this.retoActivo);
              } else {
                this.getRetoDetails();
              }
            } else {
              this.getRetoDetails();
            }
          },
          (err) => console.log('Error retrieving item:', err),
          () => console.log('Request completed')
        );

      this.currentLanguage = this.translateService.getCurrentLanguage();
    } catch (error) {
      console.error('Error in ngOnInit:', error);
    }
  }
  // Unsubscribes from all active subscriptions when the component is destroyed to prevent memory leaks.
  ngOnDestroy() {
    try {
      if (this.retoSubscription) {
        this.retoSubscription.unsubscribe();
      }
      if (this.countdownSubscription) {
        this.countdownSubscription.unsubscribe();
      }
    } catch (error) {
      console.error('Error en ngOnDestroy:', error);
    }
  }

  getRetoDetails() {
    try {
      this.isLoading = true;
      if (this.retoId) {
        this.retoSubscription = this.retosService
          .getActionById(this.retoId)
          .pipe(
            catchError((err: any) => {
              console.log('Error occurred', err);
              return throwError(err);
            })
          )
          .subscribe(
            (reto) => {
              if (reto) {
                console.log('Reto obtenido desde la API:', reto);
                this.retoActivo = reto;
                this.setRetoDetails(reto);

                // Guardar el reto en localStorage
                localStorage.setItem('storeRetoActivo', JSON.stringify(reto));
              } else {
                // Manejar caso donde el reto no se encuentra
                this.handleNotFoundError();
              }
              this.isLoading = false;
            },
            (error) => {
              console.error('Error al obtener el reto desde la API:', error);
              this.handleNotFoundError(); // Manejar el error en caso de que la API falle
              this.isLoading = false;
            }
          );
      }
    } catch (error) {
      console.error('Error en getRetoDetails:', error);
      this.isLoading = false;
    }
  }

  handleNotFoundError() {
    try {
      this.snackBar.open(
        this.translateService.translate('MAIN.ERRORS.CHALLENGE_NOT_FOUND'),
        this.translateService.translate('MAIN.BUTTONS.CLOSE_BUTTON'),
        {
          duration: 3000,
        }
      );
      // Redirigir a la página de inicio
      this.router.navigate(['/inicio']);
    } catch (error) {
      console.error('Error en handleNotFoundError:', error);
    }
  }
  
  // Sets challenge details using the retrieved data.
  setRetoDetails(reto: any) {
    try {
      const lang = reto.lang || {};
      this.dateEnd = reto.date_end ? new Date(reto.date_end) : null;
      this.title = lang.name || 'No title available';

      const file = lang.file?.main[0];
      this.foto =
        file && file.url ? file.url : 'https://github.com/sYlskY7.png';

      this.description = lang.description || 'No description available';
      this.puntosRecompensa = reto.points || 0;
      this.monedasVirtuales = reto.virtual_amount || 0;
      this.participantes = reto.participantes || '0';
      this.joined = reto.favourite;
      this.countUserFavorites = reto.favourite_users;

      const picUsers = [
        {
          id: 8,
          name: 'File 8',
          url: 'https://randomuser.me/api/portraits/men/1.jpg',
        },
        {
          id: 10,
          name: 'File 10',
          url: 'https://randomuser.me/api/portraits/men/3.jpg',
        },
        {
          id: 11,
          name: 'File 11',
          url: 'https://randomuser.me/api/portraits/men/4.jpg',
        },
      ];

      this.userFavorites = picUsers.map((user) => ({
        profilePictureUrl: user.url,
      }));

      this.userFavorites = this.getRandomUserFavorites(
        this.userFavorites,
        this.maxDisplayUsers
      );

      this.startCountdown();
    } catch (error) {
      console.error('Error en setRetoDetails:', error);
    }
  }

  // Allows the user to subscribe to the challenge and handles any errors.
  handleSubscribe() {
    try {
      if (!this.isSubscribed) {
        this.isSubscribed = true;
        this.joined = 1;
        this.displayIcon = this.favorited;

        const data = {
          action_id: this.retoId,
        };
        this.actionsService
          .addFavorite(data)
          .pipe(
            catchError((err: any) => {
              console.log('Error occurred', err);
              return throwError(err);
            })
          )
          .subscribe(
            (response) => {
              this.snackBar.open(
                this.translateService.translate('SNACKBARS.JOIN_CHALLENGE'),
                this.translateService.translate('MAIN.BUTTONS.CLOSE_BUTTON'),
                {
                  duration: 3000,
                }
              );
            },
            (error) => {
              this.isSubscribed = false;
              this.joined = 0;
              this.displayIcon = this.favIcon;
              this.snackBar.open(
                this.translateService.translate('SNACKBARS.JOIN_ERROR'),
                this.translateService.translate('MAIN.BUTTONS.CLOSE_BUTTON'),
                {
                  duration: 3000,
                }
              );
              console.error('Error al añadir a favoritos:', error);
            }
          );
      }
    } catch (error) {
      console.error('Error en handleSubscribe:', error);
    }
  }

  // Starts the countdown timer until the challenge ends.
  startCountdown() {
    try {
      if (this.dateEnd) {
        this.countdownSubscription = interval(1000)
          .pipe(
            catchError((err: any) => {
              console.log('Error occurred', err);
              return throwError(err);
            })
          )
          .subscribe(
            () => {
              const now = new Date();
              const timeDiff = this.dateEnd!.getTime() - now.getTime();

              if (timeDiff <= 0) {
                this.timeRemaining = 'El reto ha finalizado';
                this.countdownSubscription.unsubscribe();
              } else {
                const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                const hours = Math.floor(
                  (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                const minutes = Math.floor(
                  (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
                );
                const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

                this.timeRemaining = `${days}:${hours}:${minutes}:${seconds}`;
              }
            },
            (err) => console.log(' Error ', err),
            () => console.log('Request completed')
          );
      }
    } catch (error) {
      console.error('Error en startCountdown:', error);
    }
  }

  getDisplayedUserFavorites() {
    try {
      return this.userFavorites.slice(0, this.maxDisplayUsers);
    } catch (error) {
      console.error('Error en getDisplayedUserFavorites:', error);
      return [];
    }
  }

  getRandomUserFavorites(users: any[], max: number): any[] {
    try {
      if (users.length <= max) {
        return users;
      }
      const shuffled = users.slice().sort(() => 0.5 - Math.random());
      return shuffled.slice(0, max);
    } catch (error) {
      console.error('Error en getRandomUserFavorites:', error);
      return [];
    }
  }

  isMainpage(): boolean {
    try {
      return this.router.url !== '/inicio';
    } catch (error) {
      console.error('Error al comprobar la página principal:', error);
      return false;
    }
  }
}
