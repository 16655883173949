import { Component, OnInit, OnDestroy } from '@angular/core';
import { WikiService } from '@services/wiki/wiki.service';
import { HeaderComponent } from '@components/header/header.component';
import { TranslateService } from '@services/translate/translate.service';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { Subscription, catchError, throwError } from 'rxjs';
import { DataShareService } from '@services/data-share/data-share.service';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'wiki',
  standalone: true,
  imports: [AngularMaterialModule, HeaderComponent, RouterLink],
  templateUrl: './wiki.component.html',
  styleUrls: ['./wiki.component.scss'],
})
export class WikiComponent implements OnInit, OnDestroy {
  wikiItems: any[] = [];
  selectedSection: any;
  isLoading = true;
  languageSubscription: Subscription | undefined;
  selectedWikiSectionIndexSubscription: Subscription | undefined;

  constructor(
    private wikiService: WikiService,
    public translateService: TranslateService,
    private dataShareService: DataShareService
  ) {}

  ngOnInit(): void {
    // Initializes the component, subscribes to language change and section selection events.
    try {
      // Subscribes to language change and reloads Wiki items accordingly.
      this.languageSubscription = this.translateService.language$
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(() => {
          this.loadWikiItems();
        });

      // Subscribes to the Wiki section selection from another component and handles it.
      this.selectedWikiSectionIndexSubscription =
        this.dataShareService.selectedWikiSectionIndex$
          .pipe(
            catchError((err: any) => {
              console.log('Error occurred in selectedWikiSectionIndex', err);
              return throwError(err);
            })
          )
          .subscribe((index) => {
            try {
              console.log('Received Wiki section index:', index);
              // If a section is selected, load it and clear the selection.
              if (index !== null && this.wikiItems.length > index) {
                this.selectSectionByIndex(index);
                this.dataShareService.clearSelectedWikiSectionIndex();
              }
            } catch (error) {
              console.error('Error handling wiki section index:', error);
            }
          });
    } catch (error) {
      console.error('Error during ngOnInit:', error);
    }
  }

  ngOnDestroy(): void {
    // Clean up subscriptions to avoid memory leaks when the component is destroyed.
    try {
      if (this.languageSubscription) {
        this.languageSubscription.unsubscribe();
      }
      if (this.selectedWikiSectionIndexSubscription) {
        this.selectedWikiSectionIndexSubscription.unsubscribe();
      }
    } catch (error) {
      console.error('Error during ngOnDestroy:', error);
    }
  }

  loadWikiItems(): void {
    // Loads Wiki items from the API and sets the first section as selected.
    try {
      this.isLoading = true;
      this.wikiService
        .getWikiItems()
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (items) => {
            try {
              // Assigns the received items and sets the first section by default.
              this.wikiItems = items;
              this.selectedSection = items[0];
              this.isLoading = false;

              // Re-applies any stored section index after loading.
              const currentSelectedIndex =
                this.dataShareService.getSelectedWikiSectionIndex();
              if (
                currentSelectedIndex !== null &&
                items.length > currentSelectedIndex
              ) {
                this.selectSectionByIndex(currentSelectedIndex);
              }
            } catch (error) {
              console.error('Error processing wiki items:', error);
            }
          },
          (error) => {
            console.error('Error loading wiki items:', error);
            this.isLoading = false;
          }
        );
    } catch (error) {
      console.error('Error in loadWikiItems:', error);
    }
  }

  selectSectionByIndex(index: number): void {
    // Selects a section based on its index.
    try {
      if (this.wikiItems && this.wikiItems.length > index) {
        this.selectedSection = this.wikiItems[index];
      } else {
        console.warn('Wiki section index out of bounds');
      }
    } catch (error) {
      console.error('Error in selectSectionByIndex:', error);
    }
  }

  selectSection(section: any): void {
    // Allows manual section selection by the user.
    try {
      this.selectedSection = section;
    } catch (error) {
      console.error('Error in selectSection:', error);
    }
  }
}
