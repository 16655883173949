import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);  
  const loggedUser = localStorage.getItem('loggedInUser');
  let token;
  
  if (loggedUser) {
    token = JSON.parse(loggedUser).token || null;
  }
  if (token == undefined && authService.isAuth.value == false) {
    router.navigate(['/login']);
    return false;
  } else if (token != undefined || authService.isAuth.value == true) {
    return true;
  }

  return authService.isAuth.value;
};
