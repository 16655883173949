<div class="reto-activo-container">
  <header-menu></header-menu>

  <div class="content-container">
    <div class="reto-container" [ngClass]="{ 'retos-details': isMainpage() }">
      
      <!-- Spinner visible mientras isLoading sea true -->
      <mat-spinner *ngIf="isLoading"></mat-spinner>

      <!-- Contenido del reto visible cuando isLoading sea false -->
      <div *ngIf="!isLoading" class="reto-content">
        <div class="descripcion-reto">
          <div class="title-underlined">
            <h2>{{ title }}</h2>
          </div>
          <img [src]="foto" alt="Challenge image" class="reto-img" />
          <div class="underimg">
            <div class="info-reto">
              <div class="date-container" style="width: 140px">
                <mat-icon>schedule</mat-icon>
                <p class="dateP">{{ timeRemaining }}</p>
              </div>
              <div class="date-container">
                <mat-icon>paid</mat-icon>
                <p>{{ puntosRecompensa }}</p>
              </div>
              <div class="date-container">
                <mat-icon>workspace_premium</mat-icon>
                <p>{{ puntosRecompensa }}</p>
              </div>
            </div>

            <div class="joinedUsers">
              <div *ngIf="userFavorites.length > 0" class="profile-pictures">
                <img
                  *ngFor="let user of getDisplayedUserFavorites()"
                  [src]="user.profilePictureUrl"
                  alt="User profile picture"
                  class="profile-picture"
                />
              </div>
              <!-- Español -->
              <ng-container *ngIf="currentLanguage === 'es'">
                <p *ngIf="countUserFavorites === 0" class="italicP">
                  Ningún usuario se ha unido al reto
                </p>
                <p *ngIf="countUserFavorites === 1" class="italicP">
                  Se ha unido {{ countUserFavorites }} usuario
                </p>
                <p *ngIf="countUserFavorites > 1" class="italicP">
                  Se han unido {{ countUserFavorites }} usuarios
                </p>
              </ng-container>

              <!-- Inglés -->
              <ng-container *ngIf="currentLanguage === 'en'">
                <p *ngIf="countUserFavorites === 0" class="italicP">
                  No users have joined the challenge
                </p>
                <p *ngIf="countUserFavorites === 1" class="italicP">
                  {{ countUserFavorites }} user has joined
                </p>
                <p *ngIf="countUserFavorites > 1" class="italicP">
                  {{ countUserFavorites }} users have joined
                </p>
              </ng-container>

              <!-- Holandés -->
              <ng-container *ngIf="currentLanguage === 'nl'">
                <p *ngIf="countUserFavorites === 0" class="italicP">
                  Geen gebruikers hebben zich bij de uitdaging aangesloten
                </p>
                <p *ngIf="countUserFavorites === 1" class="italicP">
                  {{ countUserFavorites }} gebruiker is toegetreden
                </p>
                <p *ngIf="countUserFavorites > 1" class="italicP">
                  {{ countUserFavorites }} gebruikers zijn toegetreden
                </p>
              </ng-container>
              
            </div>
          </div>

          <p class="reto-description">{{ description }}</p>
          <br /><br />
          <button
            id="subscribeButton"
            [ngClass]="{
              joined: joined === 1,
              'disabled-button': joined === 1
            }"
            mat-flat-button
            class="custom-button text-white-color"
            color="warn"
            (click)="handleSubscribe()"
            [disabled]="joined === 1"
          >
            <span *ngIf="joined === 1" class="text-bold">Ya te has unido</span>
            <span *ngIf="joined === 0" class="text-bold">Quiero unirme</span>
          </button>

          <br /><br />
          <a routerLink="/inicio" class="backHome text-bold">
            <mat-icon class="arrow-icon">arrow_back</mat-icon>
            {{ translateService.translate("MAIN.LINKS.BACK_HOME") }}
          </a>
        </div>
      </div>

      <div
        class="retos-container generic-container"
        [ngClass]="{ 'retos-details': isMainpage() }"
      >
        <retos></retos>
        <br />
        <br />
        <div class="image-button-puntos" [routerLink]="['/contacto/puntos']" [queryParams]="{ actionName: title }">
          <div class="container-background"></div>
          <div class="container-text">
            <img src="../../../../assets/boton-solicitar-puntos.png" />
            <div class="h2-button-text">
              {{ translateService.translate("CONTACT_FORM.WISH_TO_REQUEST_POINTS") }}
            </div>
            <div class="h2-button-text-link">
              {{ translateService.translate("CONTACT_FORM.REQUEST") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="image-button-gamba" [routerLink]="['/contacto/soporte']">
      <div class="container-background"></div>
      <div class="container-text">
        <img src="../../../../assets/boton-hablar-gamba.png" />
        <div class="h2-button-text">
          {{ translateService.translate("MAIN.BUTTONS.TALK_TO_THE_GAMBA") }}
        </div>
        <div class="h2-button-text-link">
          {{ translateService.translate("CONTACT_FORM.TALK_NOW") }}
        </div>
      </div>
    </div>
    <br /><br />
    <div class="content-powered-by">
      <img src="../../../../assets/powered-by-logo.png" />
    </div>
  </div>
</div>