<div class="noticias">
  <mat-spinner *ngIf="isLoading"></mat-spinner>

  <div class="no-results" *ngIf="noticias.length === 0 && !isLoading">
    <h1>{{ translateService.translate("MAIN.ERRORS.NEWS_NOT_FOUND") }}</h1>
  </div>

  <div *ngIf="!isLoading && noticias.length >= 2">
    <div class="title-underlined">
      <h2 *ngIf="!showAllNews">
        {{ translateService.translate("MAIN.COMPONENTS.LAST_NEWS") }}
      </h2>
      <h2 *ngIf="showAllNews">
        {{ translateService.translate("MAIN.COMPONENTS.NEWS") }}
      </h2>
    </div>
    <br />
    
    <div class="container-noticias noticia-card-allnews" *ngIf="showAllNews">
      <div *ngFor="let noticia of noticias | slice:0:visibleNewsCount">
        <div
          (click)="newsDetails(noticia)"
          [routerLink]="['/noticia-details', noticia.id]"
        >
          <noticia [noticia]="noticia" [showAllNews]="showAllNews"></noticia>
        </div>
      </div>
      <br/>
      <a routerLink="/inicio" class="backHome" (click)="resetNewsState()">
        <mat-icon class="arrow-icon">arrow_back</mat-icon>
        {{ translateService.translate("MAIN.LINKS.BACK_HOME") }}
      </a>
      <a *ngIf="visibleNewsCount < noticias.length" (click)="loadMoreNews()" class="loadMoreNews">
        {{ translateService.translate("MAIN.LINKS.LOAD_MORE_NEWS") }}
      </a>
    </div>

    <div class="container-noticias" *ngIf="!showAllNews">
      <div *ngFor="let noticia of noticias; let i = index">
        <div
          (click)="newsDetails(noticia)"
          [routerLink]="['/noticia-details', noticia.id]"
        >
          <ng-container *ngIf="!showAllNews && i < 4">
            <noticia [noticia]="noticia" [showAllNews]="showAllNews"></noticia>
          </ng-container>
        </div>
      </div>
    </div>

    <div
      *ngIf="!isLoading && !showAllNews && noticias.length > 0"
      class="allNews"
    >
      <br />
      <br />
      <a (click)="handleNews()" class="text-bold">
        {{ translateService.translate("MAIN.LINKS.ALL_NEWS") }}
      </a>
    </div>

    <div class="image-button-gamba" [routerLink]="['/contacto/soporte']">
      <div class="container-background"></div>
      <div class="container-text">
        <img src="../../../../assets/boton-hablar-gamba.png" />
        <div class="h2-button-text">
          {{ translateService.translate("MAIN.BUTTONS.TALK_TO_THE_GAMBA") }}
        </div>
        <div class="h2-button-text-link">
          {{ translateService.translate("CONTACT_FORM.TALK_NOW") }}
        </div>
      </div>
    </div>
  </div>
</div>
