import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataShareService } from '@services/data-share/data-share.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseURL: string;
  public isAuth = new BehaviorSubject<boolean>(false); // BehaviorSubject to track user authentication status

  constructor(
    private router: Router,
    private http: HttpClient,
    private dataShare: DataShareService
  ) {
    // Set base URL depending on whether the environment is local or deployed
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      this.baseURL = `${environment.url}`; // Use production URL
    }
  }

  /**
   * Generates headers with the active access token stored in localStorage.
   * If noAuthorization is passed as true, the Authorization header is omitted.
   */
  getHeadersWithToken(noAuthorization?: boolean): any {
    const socialUser = localStorage.getItem('socialUser') || '';
    if (socialUser) {
      var person = JSON.parse(socialUser); // Parse user data from localStorage
    }
    let headers = {};

    // Check if the user has an ID token
    if (person.idToken) {
      headers = this.getHeaders(person, noAuthorization); // Retrieve headers with token
    } else {
      console.log('*****ACCESS TOKEN NOT FOUND*****');
      this.router.navigateByUrl('/login'); // Redirect to login if no token is found
    }

    return headers;
  }

  /**
   * Constructs headers for API requests using provided access token.
   * Optionally removes Authorization header if noAuthorization is true.
   */
  getHeaders(params: string, noAuthorization?: boolean): any {
    let headers: any;
    if (params) {
      const loggedUser = localStorage.getItem('loggedInUser')
        ? localStorage.getItem('loggedInUser')
        : null;
      let accessToken = '';
      if (loggedUser) {
        accessToken = JSON.parse(loggedUser).token; // Extract access token from logged-in user data
      }

      // Build headers including the access token
      headers = {
        Authorization: `${accessToken}`,
        ChannelToken: `Bearer ${environment.channelToken}`,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Accept: 'application/json, */*',
      };

      // Optionally remove the Authorization header
      if (noAuthorization && headers?.Authorization) {
        delete headers.Authorization;
      }
    } else {
      // Handle invalid token cases by redirecting to login
      console.error('*****INVALID TOKEN*****');
      this.router.navigateByUrl('/login');
    }
    return headers;
  }

  /**
   * Fetches company data for the user based on their userId.
   */
  getCompanyData(userId: string) {
    const endpoint = `/endpointurl/companydata/${userId}`; // API endpoint for company data
    return this.http.get(this.baseURL + endpoint, {
      headers: this.getHeadersWithToken(), // Add authentication headers
    });
  }

  /**
   * Fetches company users with a specific role and channel ID.
   */
  getCompanyUsers(userId: string) {
    const endpoint = `${environment.api_company}company/users?channel_id=2&role=1`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.getHeadersWithToken(), // Add authentication headers
    });
  }

  /**
   * Fetches the user profile information based on the userId.
   */
  getCompanyUserProfile(userId: string) {
    const endpoint = `/endpointurl/userprofile/${userId}`; // API endpoint for user profile
    return this.http.get(this.baseURL + endpoint, {
      headers: this.getHeadersWithToken(), // Add authentication headers
    });
  }

  /**
   * Retrieves an authentication token using the user's social login token.
   */
  getAuthToken(socialToken: string): Observable<any> {
    const headers = this.getHeadersWithToken(); // Add authentication headers
    const body = {
      channel_id: 2,
      socialToken: socialToken,
      driver: 'google', // Social login provider
    };
    const endpoint = `${environment.api_user}loginsocial`; // API endpoint for social login
    return this.http.post(this.baseURL + endpoint, body, {
      headers: headers,
    });
  }

  /**
   * Fetches a refresh token using the provided authentication token.
   */
  getRefreshToken(authToken: string) {
    const headers = {}; // Empty headers for this request
    const endpoint = `/endpointurl/getrefreshtoken/${authToken}`; // API endpoint for refresh token
    return this.http.get(this.baseURL + endpoint, {
      headers: headers,
    });
  }

  /**
   * Refreshes the authentication token using the refresh token stored in localStorage.
   * Triggers an update for the token in the DataShareService.
   */
  refreshToken() {
    this.dataShare.setUpdateAuthToken(true); // Notify token refresh
    let refreshToken = '';
    const loggedUser = localStorage.getItem('loggedInUser')
      ? localStorage.getItem('loggedInUser')
      : null;
    if (loggedUser) {
      refreshToken = JSON.parse(loggedUser).refresh_token; // Extract refresh token from localStorage
    }

    const noAuthorization = true; // Disable Authorization header for this request
    const body = {
      channel_id: 2,
      refresh_token: refreshToken,
    };

    const endpoint = `${environment.api_user}refreshtoken?channel_id=2&refresh_token=${refreshToken}`; // API endpoint for refresh token
    return this.http.post(
      this.baseURL + endpoint,
      {},
      {
        headers: this.getHeadersWithToken(noAuthorization), // Custom headers for refresh token
      }
    );
  }

  /**
   * Fetches the user's profile using the provided access token.
   */
  getProfile(accessToken: string) {
    const endpoint = `/api/v1/user/refreshtoken${accessToken}`; // API endpoint for profile data
    return this.http.get(this.baseURL + endpoint, {
      headers: this.getHeadersWithToken(), // Add authentication headers
    });
  }

  /**
   * Logs out the user by invalidating their session and token.
   */
  logout(remember_token: string) {
    const body = {
      channel_id: 2,
      remember_token: remember_token, // Pass the remember token for logout
    };
    const endpoint = `${environment.api_user}logout?channel_id=2&remember_token=${remember_token}`; // API endpoint for logout
    return this.http.post(
      this.baseURL + endpoint,
      {},
      {
        headers: this.getHeadersWithToken(), // Add authentication headers
      }
    );
  }

  /**
   * Checks if the authentication token is expired by comparing the stored token time with the current time.
   * @returns true if token is expired, false otherwise.
   */
  checkAuthTokenExpiration() {
    const date = new Date(); // Get current time
    const storedAuthTokenTime = localStorage.getItem('authToken_time'); // Retrieve token issue time

    // Check if token has expired (more than 5 minutes)
    if (
      storedAuthTokenTime &&
      date.getTime() - parseInt(storedAuthTokenTime) > 300000
    ) {
      return true; // Token has expired
    }
    return false; // Token is still valid
  }
}
