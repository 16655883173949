import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { TranslateService } from '@services/translate/translate.service';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { ConfigDialogComponent } from '../dialogs/config-dialog/config-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@services/auth/auth.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { AppComponent } from 'app/app.component';
import { UserProfileService } from '@services/user-profile/user-profile.service';
import { Subscription, catchError, throwError } from 'rxjs';
import { DataShareService } from '@services/data-share/data-share.service';

@Component({
  selector: 'header-menu',
  standalone: true,
  imports: [AngularMaterialModule, RouterOutlet, RouterModule, AppComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  socialUser: any = {}; // Store social user information
  localUser: any = {};  // Store local user information
  loggedUserName = true;
  userVirtualAmount: number | null = null;  // Virtual currency balance of the user
  appName = require('../../../../package.json').name; // App name from package.json

  constructor(
    private dataShare: DataShareService,
    private oAuthService: SocialAuthService,
    private authService: AuthService,
    private router: Router,
    private appComponent: AppComponent,
    public translateService: TranslateService,
    public dialog: MatDialog,
    private userProfileService: UserProfileService  // Inject UserProfileService
  ) {}

  // Initializes the component by retrieving user data and virtual amount
  ngOnInit() {
    try {
      const socialUser = localStorage.getItem('socialUser');
      const localUser = localStorage.getItem('localUser');
      if (socialUser) {
        this.socialUser = JSON.parse(socialUser);
      }
      if (localUser) {
        this.localUser = JSON.parse(localUser);
      }

      this.getUserVirtualAmount(); // Fetch virtual currency balance
    } catch (error) {
      console.error('Error en ngOnInit:', error);
    }
  }

  // Checks if the current route is not the main page ("/inicio")
  isNotMainPage(): boolean {
    try {
      return this.router.url !== '/inicio';
    } catch (error) {
      console.error('Error al verificar si no está en la página principal:', error);
      return true; // Fallback in case of error
    }
  }

  // Translates a given key using the TranslateService
  translate(key: string): string {
    try {
      return this.translateService.translate(key);
    } catch (error) {
      console.error('Error en translate:', error);
      return key; 
    }
  }

  // Retrieves the user's virtual currency balance from the UserProfileService
  getUserVirtualAmount(): void {
    try {
      this.userProfileService
        .getUserProfile()
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (response) => {
            try {
              if (response?.data?.length > 0 && response.data[0].user?.length > 0) {
                const user = response.data[0].user[0];
                this.userVirtualAmount = user.virtual_amount; // Store the user's virtual currency balance
              }
            } catch (error) {
              console.error('Error procesando la respuesta del perfil de usuario:', error);
            }
          },
          (error) => {
            console.error('Error al obtener las monedas virtuales:', error);
          }
        );
    } catch (error) {
      console.error('Error en getUserVirtualAmount:', error);
    }
  }

  // Logs the user out and clears local storage
  logout() {
    try {
      const loggedInUser = localStorage.getItem('loggedInUser') || null;
      if (loggedInUser) {
        const remember_token = localStorage.getItem('remember_token');
        if (remember_token) {
          this.appComponent.performLogout(remember_token);
        } else {
          this.appComponent.localLogout();
        }
      }

      this.authService.isAuth.next(false);
      localStorage.clear();
      this.router.navigateByUrl('/login');
      this.oAuthService.signOut().then(
        (data) => {
          console.log('signed out from google');
        },
        (error) => {
          console.log('error signing out from google: ' + error.message);
        }
      );
      window.location.reload();
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }

  // Opens the configuration dialog
  openConfig(): void {
    try {
      const dialogRef = this.dialog.open(ConfigDialogComponent, {
        width: '60%',
      });

      dialogRef.afterClosed().subscribe((result) => {
        // HANDLE ACTIONS AFTER CLOSING THE CONFIG DIALOG
      });
    } catch (error) {
      console.error('Error al abrir la configuración:', error);
    }
  }

  // Handles the action when navigating to the home page
  handleHome() {
    try {
      if (location.href.includes('/inicio')) {
        // updates the show main state
        this.dataShare.setUpdateShowMain(true);
      }
    } catch (error) {
      console.error('Error en handleHome:', error);
    }
  }
}