import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { ParticipantesComponent } from './participantes/participantes.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { RetosComponent } from './retos/retos.component';
import { NoticiaComponent } from './noticias/noticia/noticia.component';
import { WikiComponent } from './wiki/wiki.component';
import { ConfettiService } from '@services/confetti/confetti.service';
import { HeaderComponent } from '@components/header/header.component';
import { DataShareService } from '@services/data-share/data-share.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ParticipantesService } from '@services/participantes/participantes.service';
import { TranslateService } from '@services/translate/translate.service';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'inicio',
  standalone: true,
  imports: [
    AngularMaterialModule,
    HeaderComponent,
    ParticipantesComponent,
    NoticiasComponent,
    RetosComponent,
    NoticiaComponent,
    WikiComponent,
    RouterLink,
  ],
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, AfterViewChecked, OnDestroy {
  showMainSubscription!: Subscription;
  participantesData: any[] = [];
  userProfile: any;
  showWiki = false;   // Controls whether the Wiki component is visible
  handleNews = false; // Tracks whether the news component should be shown
  showBanner = true;  // Controls the visibility of the main banner

  constructor(
    public translateService: TranslateService,
    private dataShare: DataShareService,
    private confettiService: ConfettiService,
    private router: Router,
    private participantesService: ParticipantesService,
    private cdRef: ChangeDetectorRef  // Inject ChangeDetectorRef for manual change detection
  ) {}

  ngOnInit(): void {
    localStorage.removeItem('retoActivo');  // Remove active challenge from localStorage on init
    const socialUser = localStorage.getItem('socialUser');

    if (!socialUser) {
      // this.router.navigateByUrl('/login');  // Redirect to login if no social user found
    }

    // Subscribe to updates from DataShareService
    this.showMainSubscription = this.dataShare
      .getUpdateShowMain()
      .subscribe((update: any) => {
        if (update) {
          this.handleNews = update;
          this.showWiki = false;
          this.cdRef.detectChanges();  // Force change detection after update
        }
      });
  }

  // AfterViewChecked lifecycle hook to avoid rendering issues
  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();  // Force manual change detection after view checks
  }

  // Unsubscribe from subscriptions to avoid memory leaks
  ngOnDestroy(): void {
    if (this.showMainSubscription) {
      this.showMainSubscription.unsubscribe();
    }
  }

  // Trigger celebration with confetti
  celebrate(): void {
    this.confettiService.celebrateConfetti();
  }

  // Fetch participants data from the server
  loadParticipants(): void {
    this.participantesService
      .getUsuarios()
      .pipe(
        catchError((err: any) => {
          console.log('Error occurred', err);
          return throwError(err);
        })
      )
      .subscribe(
        (data) => {
          this.participantesData = data;
          console.log(this.participantesData);
          this.dataShare.updateRankingList(data);  // Update shared ranking list
        },
        (error) => {
          console.error('Error fetching users:', error);
        }
      );
  }

  // Check if the current route is not the main page
  isNotMainPage(): boolean {
    return this.router.url !== '/inicio';
  }

  // Update the state when news is updated
  getNewsUpdate(event: boolean): void {
    this.handleNews = event;
    this.showWiki = false;  // Hide the Wiki when news is being displayed
    this.cdRef.detectChanges();  // Force change detection after updating the state
  }
}