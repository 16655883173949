import {
  SocialLoginModule,
  GoogleSigninButtonModule,
} from '@abacritt/angularx-social-login';
import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from '@services/auth/auth.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { TranslateService } from '@services/translate/translate.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs';

@Component({
  selector: 'login',
  standalone: true,
  imports: [
    GoogleSigninButtonModule,
    SocialLoginModule,
    AngularMaterialModule,
    HttpClientModule,
    RouterOutlet,
    RouterModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  authStateSubscription!: Subscription; // Holds subscription to auth state updates
  public loginValid = true; // Controls the validity state of the login
  username = ''; // Holds the username entered by the user
  password = ''; // Holds the password entered by the user
  loggedIn: any; // Tracks the user's login state
  appName = require('../../../../package.json').name; // Retrieves the app name from package.json

  constructor(
    public translateService: TranslateService,
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private errorHandler: ErrorHandler
  ) {}

  // Initialization logic when the component is loaded
  ngOnInit() {
    const browserLanguage = this.translateService.getBrowserLanguage();
    const currentLanguage =
      localStorage.getItem('userLanguage') || browserLanguage;

    this.translateService.use(currentLanguage).subscribe();

    // Subscribe to authentication state changes
    this.authStateSubscription = this.authService.isAuth
      .pipe(
        catchError((err: any) => {
          console.log('Error occurred', err);
          return throwError(err);
        })
      )
      .subscribe(
        (isAuth) => {
          this.loggedIn = isAuth; // Update loggedIn state based on authentication
        },
        (err) => console.log('HTTP Error while performing the login', err),
        () => console.log('Login request completed')
      );
  }

  // Cleanup logic when the component is destroyed
  ngOnDestroy() {
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe(); // Unsubscribe from auth state to avoid memory leaks
    }
  }

  // Handles the login logic and shows relevant error messages
  login() {
    try {
      this.loginValid = false;
      this.snackBar.open(
        this.translateService.translate('LOGIN.ERRORS.WRONG_CREDENTIALS'),
        '',
        {
          duration: 5000,
          panelClass: ['error-snackbar'],
        }
      );
    } catch (error: any) {
      this.errorHandler.handleError(error); // Handles any errors during the login process
      this.snackBar.open(
        this.translateService.translate('LOGIN.ERRORS.LOGIN_ERROR'),
        '',
        {
          duration: 5000,
          panelClass: ['error-snackbar'],
        }
      );
    }
  }
}