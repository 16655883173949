import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, catchError, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';
import { TranslateService } from '@services/translate/translate.service';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  baseURL: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    // Set the baseURL based on whether the environment is local or deployed
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      // Set the base URL for the deployed environment
      this.baseURL = `${environment.url}`;
    }
  }

  // Method to get the user profile from the server
  getUserProfile(): Observable<any> {
    const url = `${this.baseURL}${environment.api_userProfile}?channel_id=2`;
    console.log('Request URL:', url);

    // Use the HTTP client to make a GET request with headers containing the token
    return this.http.get<any>(url, {
      headers: this.authService.getHeadersWithToken(),
    });
  }

  // Method to upload a profile image for the user
  uploadProfileImage(file: File): Observable<any> {
    const url = `${this.baseURL}${environment.api_uploadProfilePic}?channel_id=2&private=1`;

    // Create a FileReader to convert the image file to Base64
    const reader = new FileReader();

    // Return an Observable that handles the Base64 conversion and POST request
    return new Observable((observer) => {
      reader.onload = () => {
        const base64File = reader.result?.toString();

        if (base64File) {
          const body = {
            files: [base64File], // Send the file as Base64
            private: 1,
            gallery: 0, // Indicates it's a profile picture, not part of a gallery
          };

          const headers = this.authService.getHeadersWithToken();

          // Send the image to the server using POST
          this.http.post<any>(url, body, { headers }).subscribe(
            (response) => {
              console.log('Image uploaded successfully:', response);
              observer.next(response); // Emit the response
              observer.complete();
            },
            (error) => {
              console.error('Error uploading the image:', error);
              observer.error(error); // Emit the error
            }
          );
        } else {
          observer.error(new Error('Failed to convert the file to Base64'));
        }
      };

      // Handle errors that occur during file reading
      reader.onerror = (error) => observer.error(error);

      // Read the file as Data URL (Base64 encoding)
      reader.readAsDataURL(file);
    });
  }

  // Method to get the user's transactions
  getUserTransactions(): Observable<any> {
    const url = `${this.baseURL}${environment.api_user_transactions}?channel_id=2`;
    console.log('Request URL:', url);

    // Make a GET request to the transaction endpoint with authorization headers
    return this.http.get<any>(url, {
      headers: this.authService.getHeadersWithToken(),
    });
  }

  // Method to get a user's favorite actions
  getUserFavourites(): Observable<any[]> {
    const language = this.translateService.getCurrentLanguage();
    const url = `${this.baseURL}${environment.api_actions}?channel_id=2&favourite=1`;
    console.log('Request fav actions URL:', url);

    return this.http
      .get<any>(url, { headers: this.authService.getHeadersWithToken() })
      .pipe(
        map((response) => {
          // Extract and map all favorite actions with language support
          const allFavourites = response.data.flatMap((entry: any) =>
            entry.action.map((action: any) => ({
              ...action,
              lang: action.lang[0][language.toUpperCase()],
            }))
          );
          return allFavourites;
        }),
        catchError((error) => {
          console.error('Error fetching favorite actions:', error);
          return throwError(error); // Handle the error
        })
      );
  }

  // Method to get the completed actions of the user
  getUserCompleted(): Observable<any[]> {
    const language = this.translateService.getCurrentLanguage();
    const url = `${this.baseURL}${environment.api_actions}?channel_id=2&completed=1`;
    console.log('Request completed actions URL:', url);

    return this.http
      .get<any>(url, { headers: this.authService.getHeadersWithToken() })
      .pipe(
        map((response) => {
          // Extract and map completed actions for the user
          const allCompleted = response.data.flatMap((entry: any) =>
            entry.action.map((action: any) => ({
              ...action,
              lang: action.lang[0][language.toUpperCase()],
            }))
          );
          return allCompleted;
        }),
        catchError((error) => {
          console.error('Error fetching completed actions:', error);
          return throwError(error); // Handle the error
        })
      );
  }
}
