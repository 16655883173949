import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@services/translate/translate.service';
import { Router } from '@angular/router'; 
import { catchError, throwError } from 'rxjs';

interface Language {
  value: string;
  viewValue: string;
  flag?: string;
  defaultLanguage: number;
}

@Component({
  selector: 'app-config-dialog',
  templateUrl: './config-dialog.component.html',
  styleUrls: ['./config-dialog.component.scss'],
  imports: [AngularMaterialModule],
  standalone: true,
})
export class ConfigDialogComponent implements OnInit {
  selectedLanguage?: string; // Stores the selected language
  languages: Language[] = []; // List of available languages
  currentLanguage: any; // Stores the current language setting

  constructor(
    public dialogRef: MatDialogRef<ConfigDialogComponent>,
    private _snackBar: MatSnackBar,
    public translateService: TranslateService,
    private router: Router 
  ) {}

  // Initializes the component by fetching the available languages and setting the current language
  ngOnInit(): void {
    this.fetchLanguages(); // Fetch available languages
    const selectedLanguage = this.selectedLanguage
      ? this.selectedLanguage
      : this.translateService.getCurrentLanguage(); // Get the current language if none is selected
  
    this.currentLanguage = selectedLanguage; // Set the current language
  }

  // Fetches available languages from the service and processes the response
  fetchLanguages(): void {
    this.translateService
      .getCompanyLanguages()
      .pipe(
        catchError((err: any) => {
          console.log('Error occurred', err);
          return throwError(err); // Handle errors
        })
      )
      .subscribe(
        (response: any) => {
          const languagesData = response.data[0].language;
          this.languages = languagesData.map((language: any) => ({
            value: language.abbreviation.toLowerCase(),
            viewValue: language.abbreviation,
            flag: language.flag,
            defaultLanguage: language.default,
          }));
        },      
        (err) => console.log('Error', err),
        () => console.log('Request completed')
      );
  }

  // Handles the cancel action by closing the dialog
  onCancelClick(): void {
    this.dialogRef.close(); // Closes the dialog
  }

  // Processes the selected language from the dropdown
  processLanguage(event: any) {
    this.selectedLanguage = event.value; // Set the selected language
    this.currentLanguage = this.selectedLanguage; // Update the current language
  }

  // Saves the selected language and applies it using the translation service
  onSaveClick(): void {
    if (this.selectedLanguage) {
      this.translateService
        .use(this.selectedLanguage)
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err); // Handle errors
          })
        )
        .subscribe(
          () => {
            // Display success message
            this._snackBar.open(
              this.translateService.translate(
                'SNACKBARS.LANGUAGE_MODIFIED_SUCCESFULLY'
              ),
              undefined,
              {
                duration: 6000,
                verticalPosition: 'top',
              }
            );
            this.dialogRef.close(); // Close the dialog
            this.router.navigate(['/inicio']); // Navigate to home
          },
          (err) => console.log('Error', err),
          () => console.log('Request completed')
        );
    } else {
      this.dialogRef.close(); // Close the dialog if no language is selected
    }
  }
}