<div class="reto" *ngIf="reto && reto.lang">
  <mat-card
    class="reto-card animation-hover"
    [ngClass]="{
      'first-mat-card': index == 0,
      'second-mat-card': index == 1,
      'third-mat-card': index == 2
    }"
  >
    <mat-card-content>
      <div class="icon-container">
        <img
          *ngIf="reto.lang.file.main[0]?.url"
          [src]="reto.lang.file.main[0].url"
          alt="{{ reto.lang.file.main[0].alt_text }}"
          class="reto-image"
        />
        <img *ngIf="!reto.lang.file.main[0]?.url" src="../../../../../assets/retoimage.jpg" class="reto-image">
      </div>
      <div class="container-right" fxLayout="row" fxLayoutGap="10">
        <div class="first" fxFlex="75%">
          <p matTooltip="{{ reto.lang.name }}" class="reto-name">{{ reto.lang.name }}</p>
          <p class="reto-shortDescription">
            {{ reto.lang.short_description }}
          </p>
          <p class="points-style">
            {{ reto.points }}
            {{ translateService.translate("CHALLENGES.POINTS") }}
          </p>
        </div>
        <div class="second" fxFlex="20%">
          <mat-icon
            matBadge="{{ reto.favourite_users }}"
            matBadgePosition="above after"
            >people_outline</mat-icon
          >
          <a class="action-link">
            {{ translateService.translate("MAIN.LINKS.SIGN_UP") }}
          </a>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
