import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AuthService } from '@services/auth/auth.service';
import { TranslateService } from '@services/translate/translate.service';

@Injectable({
  providedIn: 'root',
})
export class ContactFormService {
  private baseURL: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    // Determine if the app is running in local or deployed environment
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`; // Local URL setup
    } else {
      this.baseURL = `${environment.url}`; // Production URL setup
    }
  }

  /**
   * Sends a request to gain points by submitting an action with an optional file and a text area input.
   * @param action_id - The ID of the action for which points are requested.
   * @param file - Optional file that can be attached to the request.
   * @param textArea - Optional text input that can accompany the request.
   * @returns An Observable containing the server response.
   */
  sendPointsRequest(
    action_id: number,
    file: File | null = null,
    textArea: string = ''
  ): Observable<any> {
    const lang = this.translateService.getCurrentLanguage(); // Get the current language setting
    const url = `${this.baseURL}${environment.api_request_points}&channel_id=2&action_id=${action_id}&lang=${lang}`; // Build request URL
    const headers = this.authService.getHeadersWithToken(); // Retrieve headers with token

    const body: any = {}; // Prepare request body
    if (textArea) {
      body.textArea = textArea; // Add text input if provided
    }
    if (file) {
      body.file = file; // Add file if provided
    }

    // Send POST request and handle any errors
    return this.http.post<any>(url, body, { headers }).pipe(
      catchError((error) => {
        console.error('Error sending points request form:', error);
        return throwError(error); // Handle the error by throwing it
      })
    );
  }

  /**
   * Sends a support request to the server with optional file and action ID.
   * @param textArea - The message from the user for the support request.
   * @param file - Optional file that can be attached to the support request.
   * @param action_id - Optional ID of the action related to the support request.
   * @returns An Observable containing the server response.
   */
  sendSupportRequest(
    textArea: string,
    file: File | null = null,
    action_id?: number
  ): Observable<any> {
    const lang = this.translateService.getCurrentLanguage(); // Get the current language setting
    let url = `${this.baseURL}${environment.api_support}?channel_id=2&lang=${lang}`; // Build base URL for support request

    // If an action ID is provided, append it to the URL
    if (action_id !== undefined) {
      url += `&action_id=${action_id}`;
    }

    const headers = this.authService.getHeadersWithToken(); // Retrieve headers with token

    const body: any = { textArea }; // Prepare request body with textArea
    if (file) {
      body.file = file; // Add file if provided
    }

    // Send POST request and handle any errors
    return this.http.post<any>(url, body, { headers }).pipe(
      catchError((error) => {
        console.error('Error sending support request form:', error);
        return throwError(error); // Handle the error by throwing it
      })
    );
  }
}
