import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@services/translate/translate.service';
@Injectable({
  providedIn: 'root',
})
export class NoticiasService {
  baseURL: string;

  // Constructor to inject necessary services and determine the base URL based on environment.
  constructor(
    private authService: AuthService, // Service for authentication handling and token management
    private http: HttpClient, // HttpClient for making HTTP requests
    private translateService: TranslateService // Service for handling translations
  ) {
    // Determine the base URL based on whether the environment is local or deployed.
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      // Use the production URL when deployed.
      this.baseURL = `${environment.url}`;
    }
  }

  // Method to get news articles with pagination.
  getNews(page: number = 0, limit: number = 10): Observable<any[]> {
    // Get the current language setting from the translation service.
    const language = this.translateService.getCurrentLanguage();
    // Construct the URL for the request, including language and pagination parameters.
    const url = `${this.baseURL}${environment.api_news}?channel_id=2&lang=${language}&page=${page}&limit=${limit}`;
    console.log('Request URL:', url);

    // Make a GET request to retrieve the news articles.
    return this.http
      .get<any>(url, { headers: this.authService.getHeadersWithToken() }) // Include authentication headers.
      .pipe(
        // Transform the response to return the relevant news article data.
        map((response) => {
          return response.data[0].article.map((article: any) => ({
            id: article.id,
            date_start: article.date_start, // Extract start date of the article.
            lang: article.lang.find(
              (l: any) => l.hasOwnProperty(language.toUpperCase()) // Match the article language to the current language.
            )[language.toUpperCase()],
          }));
        }),
        // Handle any errors that may occur during the HTTP request.
        catchError((error) => {
          console.error('Error al obtener noticias:', error);
          return throwError(error); // Return an observable error.
        })
      );
  }

  // Method to retrieve a specific news article by its ID.
  getNoticiaById(noticiaId: string): Observable<any> {
    // Get the current language setting in uppercase.
    const language = this.translateService.getCurrentLanguage().toUpperCase();
    // Construct the URL to retrieve a specific news article by ID and language.
    const url = `${this.baseURL}${environment.api_news}?channel_id=2&lang=${language}&id=${noticiaId}`;

    // Make a GET request to retrieve the news article by ID.
    return this.http
      .get<any>(url, { headers: this.authService.getHeadersWithToken() }) // Include authentication headers.
      .pipe(
        // Transform the response to return the relevant news article data.
        map((response) => {
          const noticia = response.data[0]?.article[0]; // Get the first article from the response.
          const langData =
            noticia?.lang.find((l: any) => l[language]) || noticia?.lang[0]; // Find the matching language data.
          noticia.lang = langData
            ? langData[language] || Object.values(langData)[0]
            : null; // Assign the language content.
          return noticia; // Return the processed news article.
        }),
        // Handle any errors that may occur during the HTTP request.
        catchError((error) => {
          console.error('Error al obtener la noticia:', error);
          return throwError(error); // Return an observable error.
        })
      );
  }
}
