<div class="content-main-container">
  <header-menu></header-menu>
  <div class="content-container">
    <div
      class="contact-form-container"
      [ngClass]="{ 'retos-details': isMainpage() }"
    >
      <div *ngIf="formType == 'soporte'" class="title-underlined">
        <h2>
          {{ translateService.translate("CONTACT_FORM.GAMBA_LISTENS") }}
        </h2>
      </div>

      <h3 *ngIf="formType == 'soporte'" class="title-description">
        {{ translateService.translate("CONTACT_FORM.ASK_QUESTION") }}

        <br />
        <p>
          {{ translateService.translate("CONTACT_FORM.LEAVE_MESSAGE") }}
        </p>
        <br />
      </h3>

      <div *ngIf="formType == 'puntos'" class="title-underlined">
        <h2>
          {{ translateService.translate("CONTACT_FORM.REQUEST_POINTS") }}
        </h2>
      </div>

      <h3 class="title-description" *ngIf="formType == 'puntos'">
        {{ translateService.translate("CONTACT_FORM.EXPLAIN_EARNED_POINTS") }}
      </h3>

      <h3 class="secondary" *ngIf="formType == 'puntos'">
        {{ translateService.translate("CONTACT_FORM.SELECT_ACTION") }}
      </h3>      
      <mat-form-field
        *ngIf="formType == 'puntos'"
        class="dropdown"
        appearance="outline"
      >
        <mat-icon>keyboard_arrow_down</mat-icon>
        <mat-select
          panelClass="menu-dropdown-styles"
          [(ngModel)]="selectedAction"
          name="selectedAction"
          [compareWith]="compareActions"
        >
          <mat-option
            *ngFor="let action of actions"
            [value]="action"
            (mousedown)="selectAction(action)"
            [disabled]="action.name === noActionsMessage"
          >
            {{ action.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="comments">
        <textarea
          matInput
          [(ngModel)]="comments"
          placeholder="Escribe tu mensaje..."
          cdkTextareaAutosize
          cdkAutosizeMinRows="16"
          (keyup)="comments = $any($event.target).value; validateFormdata()"
        ></textarea>
      </mat-form-field>

      <h3 class="secondary">
        {{ translateService.translate("CONTACT_FORM.ATTACH_FILE") }}
      </h3>      
      <!--<button
        (click)="saveChanges()"
        class="submit custom-button text-white-color float-left"
        mat-flat-button
        matPrefix
      >
        {{ translateService.translate("CONTACT_FORM.UPLOAD_FILE") }}
        <mat-icon>attach_file</mat-icon>
      </button>
    -->
    <h3 class="filename">{{fileName}}</h3>
      <mat-form-field class="upload-file">
        <button
          (click)="input.click()"
          class="submit custom-button text-white-color"
          mat-raised-button
          matPrefix
        >
          Subir archivo 
          <!--<mat-icon>attach_file</mat-icon>-->
        </button>
        <input
          (click)="input.click()"
          class="hidden-input"
          type="text"
          readonly
          matInput
          [formControl]="display"
        />
        <input
          class="hidden-input"
          type="file"
          [accept]="acceptFormats"
          multiple="false"
          hidden
          #input
          (change)="checkFile(input.files)"
        />
      </mat-form-field>
      <br />
      <button
        *ngIf="formType == 'soporte'"
        mat-flat-button
        class="submit custom-button text-white-color"
        [ngClass]="{ disabled: !validForm }"
        (click)="saveChanges()"
      >
        {{ translateService.translate("CONTACT_FORM.SEND_MESSAGE") }}
      </button>

      <button
        *ngIf="formType == 'puntos'"
        mat-flat-button
        class="submit custom-button text-white-color float-left"
        [ngClass]="{ disabled: !validForm }"
        (click)="saveChanges()"
      >
        {{ translateService.translate("CONTACT_FORM.SEND_REQUEST") }}
      </button>
    </div>

    <div
      class="retos-container generic-container"
      [ngClass]="{ 'retos-details': isMainpage() }"
    >
      <retos></retos>
      <br />
      <br />
      <div
        *ngIf="formType == 'soporte'"
        class="image-button-puntos"
        routerLink="/contacto/puntos"
      >
        <div class="container-background"></div>
        <div class="container-text">
          <img src="../../../../assets/boton-solicitar-puntos.png" />
          <div class="h2-button-text">
            {{
              translateService.translate("CONTACT_FORM.WISH_TO_REQUEST_POINTS")
            }}
          </div>
          <div class="h2-button-text-link">
            {{ translateService.translate("CONTACT_FORM.REQUEST") }}
          </div>
        </div>
      </div>

      <div
        *ngIf="formType == 'puntos'"
        class="image-button-gamba"
        routerLink="/contacto/soporte"
      >
        <div class="container-background"></div>
        <div class="container-text">
          <img src="../../../../assets/boton-hablar-gamba.png" />
          <div class="h2-button-text">
            {{ translateService.translate("MAIN.BUTTONS.TALK_TO_THE_GAMBA") }}
          </div>
          <div class="h2-button-text-link">
            {{ translateService.translate("CONTACT_FORM.TALK_NOW") }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-powered-by">
    <img src="../../../../assets/powered-by-logo.png" />
  </div>
</div>
