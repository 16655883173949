export const environment = {
  issuer: 'https://accounts.google.com',
  clientId: '692559422779-cb3rr1m1gc0qtkfaj1qarsk7rs32ih81.apps.googleusercontent.com',
  channelToken: 'c746fa4189e48e537cf44df01cd7882731e4d073f837eae6ad8bc151bda819d1',
  scope: 'scope',
  production: true,
  development: false,
  local: false,
  url: 'https://dev.beehub.cloud',
  localUrl: '',
  localPort: '8083',
  api_user: '/api/v1/user/',
  api_userProfile: '/api/v1/user/profile',
  api_company: '/api/v1/company/',
  api_languages: '/api/language/',
  api_actions: '/api/v1/action',
  api_actions_addfavourite: '/api/v1/action/favourite',
  api_user_transactions: '/api/v1/transaction',
  api_request_points: '/api/v1/request',
  api_support: 'api/v1/support',
  api_news: '/api/v1/article',
  api_ranking: '/api/v1/user/ranking',
  api_wiki: '/api/v1/wiki',
  api_uploadProfilePic: '/api/v1/file'
};

//##DEV
//##client id : 692559422779-cb3rr1m1gc0qtkfaj1qarsk7rs32ih81.apps.googleusercontent.com
//channel token: c746fa4189e48e537cf44df01cd7882731e4d073f837eae6ad8bc151bda819d1
