<h1 class="config-title">
  <mat-icon class="config-icon">settings_b_roll</mat-icon>
  {{ translateService.translate("MAIN.MENU.SETTINGS") }}
</h1>
<h2 mat-dialog-title>
  {{ translateService.translate("DIALOGS.SELECT_LANGUAGE") }}
</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>
      {{ translateService.translate("DIALOGS.LANGUAGE") }}
    </mat-label>
    <mat-select
      [ngModel]="currentLanguage"
      class="selectLang"
      (selectionChange)="processLanguage($event)"
    >
      <mat-option *ngFor="let language of languages" [value]="language.value">
        <img
          [src]="'./assets/flags/' + language.flag"
          alt="Flag"
          class="flag-icon"
        />
        {{ language.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button class="cancel-button" (click)="onCancelClick()">
    {{ translateService.translate("MAIN.BUTTONS.CLOSE_BUTTON") }}
  </button>
  <button
    mat-button
    class="save-button"
    (click)="onSaveClick()"
    cdkFocusInitial
  >
    {{ translateService.translate("MAIN.BUTTONS.SAVE_BUTTON") }}
  </button>
</mat-dialog-actions>
