
<div class="login-container">
  <!-- Logo -->
  <div class="logo-container">
    <img src="../../../assets/pecera_logo.png" class="header-logo" alt="logo" />
  </div>
  <!-- Login Title -->
  <h2>{{ translateService.translate("LOGIN.WELCOME") }} {{ appName }}</h2>
  <p class="description">
    {{ translateService.translate("LOGIN.GOOGLE_DESCRIPTION") }}
  </p>
  <!-- Google SSO Button -->
  <asl-google-signin-button
    *ngIf="!loggedIn"
    style="display: inline-block"
    type="icon"
    size="large"
  ></asl-google-signin-button>
  <!-- Support Links -->
  <div class="support-links">
    <a href="#"> {{ translateService.translate("LOGIN.PROBLEMS_LOGIN") }} </a> |
    <a href="#"> {{ translateService.translate("LOGIN.POLICY") }} </a>
  </div>
</div>
