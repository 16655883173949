import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { environment } from 'environments/environment';
import { TranslateService } from '../translate/translate.service';

@Injectable({
  providedIn: 'root',
})
export class WikiService {
  private baseURL: string; // Base URL for API requests

  constructor(
    private http: HttpClient, // Injecting HttpClient to make HTTP requests
    private authService: AuthService, // Injecting AuthService to manage authentication
    private translateService: TranslateService // Injecting TranslateService to handle language translation
  ) {
    // Checking if the environment is local or deployed to set the appropriate base URL
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      this.baseURL = `${environment.url}`;
    }
  }

  // Method to retrieve Wiki items from the server, accepting pagination parameters
  getWikiItems(page: number = 0, limit: number = 10): Observable<any[]> {
    const language = this.translateService.getCurrentLanguage(); // Fetching the current language
    const url = `${this.baseURL}${environment.api_wiki}?channel_id=2&lang=${language}&page=${page}&limit=${limit}`;
    console.log('Request URL:', url); // Logging the request URL for debugging

    // Making a GET request to the API with authentication headers
    return this.http
      .get<any>(url, { headers: this.authService.getHeadersWithToken() })
      .pipe(
        map((response) => {
          // Validating the structure of the API response
          if (response && response.data && response.data[0]?.wiki) {
            // Mapping over each Wiki item and extracting language-specific content
            return response.data[0].wiki.map((wiki: any) => {
              const langData = wiki.lang.find(
                (langObj: any) => langObj[language.toUpperCase()]
              );
              return langData
                ? { ...wiki, ...langData[language.toUpperCase()] }
                : wiki;
            });
          }
          throw new Error('Invalid response structure'); // Throwing an error if the response structure is incorrect
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Error fetching wiki items:', error.message); // Logging error details
          if (error.status === 500) {
            console.error('Server error details:', error.error); // Additional logging for server errors
          }
          return throwError(error); // Propagating the error for further handling
        })
      );
  }
}
