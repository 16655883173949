import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HeaderComponent } from '@components/header/header.component';
import { RetosComponent } from '@components/main/retos/retos.component';
import { TranslateService } from '@services/translate/translate.service';
import { AppComponent } from 'app/app.component';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { UserProfileService } from '@services/user-profile/user-profile.service';
import { ContactFormService } from '@services/contact-form/contact-form.service';
import { DataShareService } from '@services/data-share/data-share.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'contact-form',
  standalone: true,
  imports: [
    HeaderComponent,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    RetosComponent,
    AppComponent,
  ],
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  fileToUpload: any; 
  validForm = false; 
  formType = ''; 
  display: FormControl = new FormControl('', Validators.required); 
  acceptFormats = 'image/png, image/jpeg, image/gif, image/jpg, image/svg'; 
  actions: { id: number; name: string }[] = []; 
  isLoading = true; 
  noActionsMessage = 'No tienes ninguna acción en curso...'; 
  selectedAction: any = ''; 
  message: string = ''; 
  autoCompleteActionName: string | null = null; 
  comments: string = ''; 
  fileName = ''; 

  constructor(
    private router: Router,
    public translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private userProfileService: UserProfileService,
    private contactFormService: ContactFormService,
    private dataShareService: DataShareService 
  ) {}

  // Initializes the component and sets up data based on route parameters
  ngOnInit() {
    this.activatedRoute.params
      .pipe(
        catchError((err: any) => {
          console.log('Error occurred', err);
          return throwError(err);
        })
      )
      .subscribe(
        (params) => {
          this.formType = params['formType']; // Sets the form type from the route params
          console.log('Form type:', this.formType);
          this.fileToUpload = null;
          this.selectedAction = '';
          this.comments = '';
        },
        (err) => console.log('Error while getting the Social User', err),
        () => console.log('Social User request completed')
      );

    // Sets up action auto-completion based on shared data from another component
    this.dataShareService.reto$.subscribe(
      (reto) => {
        if (reto && reto.lang && reto.lang.name) {
          this.autoCompleteActionName = reto.lang.name;
          console.log(
            'Auto-completing action name:',
            this.autoCompleteActionName
          );
        }
      },
      (err) => console.log('Error', err),
      () => console.log('Request completed')
    );

    // Loads the user's favorite actions from the profile service
    this.loadUserFavourites();
  }

  // Fetches user's favorite actions and handles errors if any occur
  loadUserFavourites() {
    this.userProfileService
      .getUserFavourites()
      .pipe(
        catchError((err: any) => {
          console.log('Error occurred', err);
          return throwError(err);
        })
      )
      .subscribe(
        (response: any) => {
          console.log('Full API response:', response);
          this.actions = [];

          if (response && response.length > 0) {
            response.forEach((action: any) => {
              const langData = action.lang || {};
              const actionName = langData.name || 'No Title';
              const actionId = action.id;
              this.actions.push({ id: actionId, name: actionName });
              console.log(`Action loaded: ${actionName} with ID: ${actionId}`);

              // Auto-complete if action matches shared data
              if (
                this.autoCompleteActionName &&
                actionName === this.autoCompleteActionName
              ) {
                this.selectedAction = { id: actionId, name: actionName };
                console.log('Autocompleted action:', this.selectedAction);
              }
            });
          } else {
            console.warn('No valid data available in the response.');
            this.actions.push({ id: 0, name: this.noActionsMessage });
          }

          if (this.actions.length === 0) {
            console.warn('No favorite actions available.');
            this.actions.push({ id: 0, name: this.noActionsMessage });
          } else {
            console.log('Favorite actions displayed:', this.actions);
          }

          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching favorite actions:', error);
          this.actions.push({ id: 0, name: this.noActionsMessage });
          this.isLoading = false;
        },
        () => console.log('Request completed')
      );
  }

  // Handles file selection and displays the file name in the form
  checkFile(files: any): void {
    if (files.length) {
      this.fileToUpload = files[0];
      this.fileName = this.fileToUpload.name;
      if (this.fileToUpload) {
        this.display.patchValue(`${this.fileToUpload.name}`);
      }

      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
    } else {
      this.display.patchValue('');
      this.validForm = false;
    }
  }

  // Sets the selected action from the dropdown
  selectAction(action: { id: number; name: string }) {
    console.log('Selected action:', action);
    this.selectedAction = action;
    this.validateFormdata(); // Validates the form after action selection
  }

  // Saves form data based on form type ('puntos' or 'soporte')
  saveChanges() {
    if (this.formType === 'puntos') {
      if (
        !this.selectedAction ||
        this.selectedAction.name === this.noActionsMessage
      ) {
        console.warn('No valid action selected.');
        return;
      }

      const action_id = this.selectedAction.id;

      this.contactFormService
        .sendPointsRequest(action_id, this.fileToUpload, this.message)
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (response) => {
            console.log('Points request form submitted successfully:', response);
          },
          (error) => {
            console.error('Error submitting points request form:', error);
          }
        );
    } else if (this.formType === 'soporte') {
      this.contactFormService
        .sendSupportRequest(this.message, this.fileToUpload)
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (response) => {
            console.log('Support form submitted successfully:', response);
          },
          (error) => {
            console.error('Error submitting support form:', error);
          }
        );
    }
  }

  // Checks if the current page is not the main page
  isMainpage(): boolean {
    return this.router.url !== '/inicio';
  }

  // Validates the form data based on the form type and the user's input
  validateFormdata() {
    this.validForm = false;
    if (this.formType === 'puntos' && this.selectedAction && this.comments.trim().length > 0) {
      this.validForm = true;
    } else if (this.formType === 'soporte' && this.comments.trim().length > 0) {
      this.validForm = true;
    }
  }

  // Compares two actions for equality, used for the action selection
  compareActions(
    c1: { id: number; name: string },
    c2: { id: number; name: string }
  ): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}