import {
  SocialAuthService,
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';
import { HttpClientModule } from '@angular/common/http';
import { Component, ErrorHandler } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@services/auth/auth.service';
import { DataShareService } from '@services/data-share/data-share.service';
import { Subscription, catchError, delay, of, throwError } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  providers: [],
  imports: [RouterOutlet, HttpClientModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  appVersion = require('../../package.json').version;
  appName = require('../../package.json').name;
  authStateSubscription!: Subscription;
  authTokenSubscription!: Subscription;
  socialTokenSubscription!: Subscription;
  authTokenTimeout = 300000; // 5 min token timeout
  refreshAuthTokenCalled = false;
  accessToken = '';

  constructor(
    private router: Router,
    private oAuthService: SocialAuthService,
    private authService: AuthService,
    private dataShare: DataShareService,
    private errorHandler: ErrorHandler,
    private jwtHelper: JwtHelperService,
    private title: Title
  ) {
    this.title.setTitle(this.appName);
  }

  ngOnInit() {
    try {
      const loggedInUser = localStorage.getItem('loggedInUser');
      const socialUser = localStorage.getItem('socialUser');

      if (loggedInUser && socialUser) {
        const expired = this.authService.checkAuthTokenExpiration();
        if (expired) {
          console.log('AUTH TOKEN EXPIRED!');
          this.refreshAuthToken();
        }

        this.trackAuthTokenExpiration(JSON.parse(socialUser).idToken);
        this.trackSocialTokenExpiration(JSON.parse(socialUser).idToken);

        if (location.href.includes('/login')) {
          this.router.navigateByUrl('/inicio');
        }
      } else {
        const remember_token = localStorage.getItem('remember_token');
        if (remember_token) {
          this.performLogout(remember_token);
        } else {
          this.localLogout();
        }
      }

      this.authStateSubscription = this.oAuthService.authState
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (socialUser: any) => {
            if (socialUser && socialUser != null) {
              this.localLogout();
              console.log(socialUser);
              localStorage.setItem('socialUser', JSON.stringify(socialUser));
              if (socialUser.provider == 'GOOGLE') {
                this.getAccessToken(socialUser.provider);
                this.trackSocialTokenExpiration(socialUser.idToken);
                const loggedInUser = localStorage.getItem('loggedInUser');
                if (loggedInUser) {
                  this.trackAuthTokenExpiration(socialUser.idToken);
                }
              }
            }
          },
          (err) => console.log(' Error while getting the Social User', err),
          () => console.log('Social User request completed')
        );
    } catch (error) {
      this.errorHandler.handleError(error); // Captura los errores
    }
    console.log('%c -= APP NAME: ' + this.appName + ' =-', 'color: orange;');
    console.log(
      '%c -= APP VERSION: ' + this.appVersion + ' =-',
      'color: orange;'
    );
  }

  ngOnDestroy() {
    try {
      const loggedInUser = localStorage.getItem('loggedInUser') || null;
      if (loggedInUser) {
        const remember_token = localStorage.getItem('remember_token');
        if (remember_token) {
          this.performLogout(remember_token);
        } else {
          this.localLogout();
        }
      }
      if (this.authStateSubscription) {
        this.authStateSubscription.unsubscribe();
      }
      if (this.authTokenSubscription) {
        this.authTokenSubscription.unsubscribe();
      }
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  performLogout(rememberToken: string) {
    try {
      if (this.authTokenSubscription) {
        this.authTokenSubscription.unsubscribe();
      }
      if (this.authStateSubscription) {
        this.authStateSubscription.unsubscribe();
      }
      if (localStorage.length > 0) {
        this.authService
          .logout(rememberToken)
          .pipe(
            catchError((err: any) => {
              console.log('Error occurred', err);
              return throwError(err);
            })
          )
          .subscribe(
            (response: any) => {
              console.log('logout was correct');
            },
            (msg) => {
              console.log(
                'error performing the logout: ' +
                  msg.status +
                  ' ' +
                  msg.statusText
              );
              this.localLogout();
            }
          );
      }
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  localLogout() {
    try {
      if (this.authTokenSubscription) {
        this.authTokenSubscription.unsubscribe();
      }
      if (this.authStateSubscription) {
        this.authStateSubscription.unsubscribe();
      }
      this.authService.isAuth.next(false);
      localStorage.clear();
      this.router.navigateByUrl('/login');
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  getAccessToken(provider: string): void {
    try {
      this.oAuthService
        .getAccessToken(provider)
        .then((accessToken: any) => {
          localStorage.setItem('accessToken', accessToken);
          console.log('------accessToken------', accessToken);

          this.authService
            .getAuthToken(accessToken)
            .pipe(
              catchError((err: any) => {
                console.log('Error occurred', err);
                return throwError(err);
              })
            )
            .subscribe(
              (loggedUser: any) => {
                if (
                  loggedUser &&
                  Object.keys(loggedUser).length > 0 &&
                  loggedUser?.data[0]
                ) {
                  const date = new Date();
                  localStorage.setItem(
                    'authToken_time',
                    String(date.getTime())
                  );

                  localStorage.setItem(
                    'loggedInUser',
                    JSON.stringify(loggedUser.data[0])
                  );
                  localStorage.setItem(
                    'remember_token',
                    loggedUser.data[0].remember_token
                  );

                  const socialUser = localStorage.getItem('socialUser');
                  if (socialUser) {
                    this.trackAuthTokenExpiration(
                      JSON.parse(socialUser).idToken
                    );
                  }
                  this.authService.isAuth.next(true);
                  this.router.navigateByUrl('/inicio');
                }
              },
              (msg) => {
                console.log('error retrieving the logged user ' + msg.error);
              }
            );
        });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  refreshAccessToken() {
    try {
      this.oAuthService
        .refreshAccessToken(GoogleLoginProvider.PROVIDER_ID)
        .then((accessToken: any) => {
          localStorage.setItem('accessToken', accessToken);
          if (!accessToken) {
            this.getAccessToken(GoogleLoginProvider.PROVIDER_ID);
          } else {
            console.log('------refreshed Access token------', accessToken);
          }
        });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  refreshAuthToken() {
    if (this.refreshAuthTokenCalled === false) {
      this.refreshAuthTokenCalled = true;
      if (this.authTokenSubscription) {
        this.authTokenSubscription.unsubscribe();
      }
      const loggedUser = localStorage.getItem('loggedInUser');
      if (loggedUser) {
        try {
          this.authService
            .refreshToken()
            .pipe(
              catchError((err: any) => {
                console.log('Error occurred', err);
                return throwError(err);
              })
            )
            .subscribe(
              (loggedUser: any) => {
                if (
                  loggedUser &&
                  Object.keys(loggedUser).length > 0 &&
                  loggedUser?.data[0]
                ) {
                  const date = new Date();
                  localStorage.setItem(
                    'authToken_time',
                    String(date.getTime())
                  );

                  this.authService.isAuth.next(true);
                  localStorage.setItem(
                    'loggedInUser',
                    JSON.stringify(loggedUser.data[0])
                  );
                  const socialUser = localStorage.getItem('socialUser');
                  this.refreshAuthTokenCalled = false;
                  this.dataShare.setUpdateAuthToken(false);

                  if (socialUser) {
                    this.trackAuthTokenExpiration(
                      JSON.parse(socialUser).idToken
                    );
                  }
                }
              },
              (msg) => {
                this.dataShare.setUpdateAuthToken(false);
                this.refreshAuthTokenCalled = false;
                console.log(
                  'error retrieving the logged user: ' +
                    msg.status +
                    ' ' +
                    msg.statusText
                );
                this.performLogout(
                  localStorage.getItem('remember_token') || ''
                );
                this.router.navigateByUrl('/login');
              }
            );
        } catch (error) {
          this.errorHandler.handleError(error);
        }
      }
    }
  }

  authExpirationCounter(timeout: any) {
    try {
      let trackTime: any;
      const storedAuthTokenTime = localStorage.getItem('authToken_time');
      if (storedAuthTokenTime) {
        trackTime = new Date(parseInt(storedAuthTokenTime));
      } else {
        trackTime = String(new Date());
      }

      console.log('Start tracking auth token expiration: ' + String(trackTime));
      if (this.authTokenSubscription) {
        this.authTokenSubscription.unsubscribe();
      }

      this.authTokenSubscription = of(null)
        .pipe(delay(timeout))
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(() => {
          console.log('AUTH TOKEN EXPIRED!');
          this.refreshAuthToken();
        });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  socialExpirationCounter(timeout: any) {
    try {
      if (this.socialTokenSubscription) {
        this.socialTokenSubscription.unsubscribe();
      }
      this.socialTokenSubscription = of(null)
        .pipe(delay(timeout))
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(() => {
          console.log('SOCIAL TOKEN EXPIRED!');
          this.oAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
        });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  trackAuthTokenExpiration(accessToken: string) {
    if (accessToken) {
      const timeout = this.jwtHelper.getTokenExpirationDate(accessToken);
      if (timeout && this.authService.checkAuthTokenExpiration()) {
        this.refreshAuthToken();
      }
      this.authExpirationCounter(this.authTokenTimeout);
    }
  }

  trackSocialTokenExpiration(accessToken: string) {
    if (accessToken) {
      const timeout = this.jwtHelper.getTokenExpirationDate(accessToken);
      if (timeout && this.jwtHelper.isTokenExpired(accessToken)) {
        this.oAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
      }
      this.socialExpirationCounter(timeout);
    }
  }
}
