import { Component, Input, OnInit } from '@angular/core';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { TranslateService } from '@services/translate/translate.service';

@Component({
  selector: 'noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.scss'],
  standalone: true,
  imports: [AngularMaterialModule],
})
export class NoticiaComponent implements OnInit {
  @Input() noticia: any; // Input to accept news data from parent component
  @Input() showAllNews: boolean = false; // Input to control whether to show all news or not
  userLang = ''; // To store the user’s language setting
  noticiaLang: any = []; // Stores language-specific content for the news

  constructor(public translateService: TranslateService) {} // Injects translation service for language support

  // Initialization logic when the component is loaded
  ngOnInit() {}
}