import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/auth/auth.service';
import { TranslateService } from '@services/translate/translate.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RetosService {
  private baseURL: string;

  constructor(
    private http: HttpClient, // Inject HttpClient for making HTTP requests
    private authService: AuthService, // Inject AuthService to handle authentication
    private translateService: TranslateService // Inject TranslateService to manage language translations
  ) {
    // Determine the base URL depending on whether the environment is local or deployed
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`; // Use local URL for development
    } else {
      // Use the deployed environment URL in production
      this.baseURL = `${environment.url}`;
    }
  }

  // Method to retrieve a list of actions with pagination and language support
  getActions(page: number = 0, limit: number = 10): Observable<any[]> {
    const language = this.translateService.getCurrentLanguage(); // Get the current language
    const url = `${this.baseURL}${environment.api_actions}?channel_id=2&lang=${language}&page=${page}&limit=${limit}`;
    console.log('Request URL:', url);

    // Make an HTTP GET request to fetch the list of actions
    return this.http
      .get<any>(url, { headers: this.authService.getHeadersWithToken() })
      .pipe(
        map((response) => {
          // Flatten the response data and filter out favorite and completed actions
          const allActions = response.data.flatMap((entry: any) =>
            entry.action
              .filter((action: any) => !action.favourite && !action.completed)
              .map((action: any) => ({
                ...action,
                lang: action.lang[0][language.toUpperCase()], // Select the correct language version of the action
              }))
          );
          return allActions; // Return the list of filtered actions
        }),
        catchError((error) => {
          console.error('Error al obtener acciones:', error);
          return throwError(error); // Error handling
        })
      );
  }

  // Method to retrieve a specific action by its ID
  getActionById(actionId: string): Observable<any> {
    const language = this.translateService.getCurrentLanguage(); // Get the current language
    const url = `${this.baseURL}${environment.api_actions}?channel_id=2&lang=${language}&id=${actionId}`;
    console.log('Request URL (getActionById):', url);

    // Make an HTTP GET request to fetch a specific action by its ID
    return this.http
      .get<any>(url, { headers: this.authService.getHeadersWithToken() })
      .pipe(
        map((response) => {
          const action = response.data[0]?.action[0]; // Extract the first action from the response
          if (action && action.lang) {
            // Find the correct language data for the action
            const langData =
              action.lang.find((l: any) => l[language.toUpperCase()]) ||
              action.lang[0]; // Default to the first language if not found
            action.lang = langData
              ? langData[language.toUpperCase()] || Object.values(langData)[0]
              : null; // Set the correct language data for the action
          }
          return action; // Return the action with the correct language
        }),
        catchError((error) => {
          console.error('Error al obtener la acción por ID:', error);
          return throwError(error); // Error handling
        })
      );
  }
}
