
<div class="message-container">

    <img class="not-found-img" src="../../../assets/not found titas.png">

    <br>
    <br>

    <a class="nav-link" routerLink="/inicio"> Home page.</a>
    <!--<h1>Not Found</h1>
    <br />
    <h1> Page doesn't exist. | 
        <a class="nav-link" routerLink="inicio"> Home page.</a>
    </h1>
    -->

</div>