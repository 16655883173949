<div class="main-content-container">
  <header-menu></header-menu>

  <div *ngIf="showWiki == false" class="content-container">
    <div class="main-banner" [ngClass]="{ 'hide-banner': !showBanner }">
      <div class="container-background"></div>
      <div [ngClass]="{ 'hide-banner': !showBanner }" class="container-text">
        <img src="../../../../assets/redeem-points.png" />
        <div class="h2-button-text header-text">
          {{
            translateService.translate(
              "MAIN.BUTTONS.REDEEM_POINTS_BEFORE_EXPIRATION"
            )
          }}
          <a class="action-link white">
            {{ translateService.translate("MAIN.LINKS.VIEW_INFO") }}
          </a>
        </div>
      </div>
      <mat-icon (click)="showBanner = false" class="close-banner"
        >close</mat-icon
      >
    </div>

    <div
      class="participantes-container generic-container"
      *ngIf="handleNews != null && handleNews == false"
    >
      <div>
        <participantes></participantes>
      </div>
    </div>

    <div
      class="retos-container generic-container"
      *ngIf="handleNews != null && handleNews == false"
    >
      <retos></retos>
      <br />
      <div class="image-button-puntos" routerLink="/contacto/puntos">
        <div class="container-background"></div>
        <div class="container-text">
          <img src="../../../../assets/boton-solicitar-puntos.png" />
          <div class="h2-button-text">
            {{
              translateService.translate("CONTACT_FORM.WISH_TO_REQUEST_POINTS")
            }}
          </div>
          <div class="h2-button-text-link">
            {{ translateService.translate("CONTACT_FORM.REQUEST") }}
          </div>
        </div>
      </div>
    </div>

    <div class="noticias-container generic-container">
      <noticias (handleNews)="getNewsUpdate($event)"></noticias>
    </div>
  </div>

  <div *ngIf="showWiki" class="content-container">
    <wiki></wiki>
  </div>
  <div class="content-powered-by">
    <img src="../../../../assets/powered-by-logo.png" />
  </div>
</div>
